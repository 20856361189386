import React, {useState, useEffect, Suspense} from 'react';
import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { GlobalStyle, SchoolAppStyle } from 'GlobalStyle';
import Loader from 'components/app/loader';
import PlansAndPricing from 'containers/plansAndPricing';
// import CountDownTimer from 'components/app/countDownTimer';
import Cart from 'components/cart';
import LoginPage from "components/loginPage";
import SignUpPage from 'components/signUpPage';
import ActivateUserPage from 'components/activateUserPage';
import TermsOfService from 'components/voskoolTermOfService';
import PrivacyPolicy from 'components/voskoolTermOfService/privacyPolicy';
import PasswordResetPage from 'components/passwordResetPage';
import PasswordResetConfirmationPage from 'components/passwordResetPage/confirmationPage';
import CreateSchoolAccount from 'components/createSchoolAccount';
// import Footer from "components/app/footer";

// import { LAUNCH_DATE } from 'actions/url';

import voskoolLogo from 'images/voskool_logo.png';

// import { phoneDivider } from 'actions/phone';
// const GlobalStyle = lazy(() => import('exportGlobalStyle'));
// const Layout = lazy(() => import('components/app/vo/layout'));
// const NavBar = lazy(() => import('components/navbar'));
// const Home = lazy(() => import('containers/home'));
// const Schools = lazy(() => import('containers/schools'));
// const SchoolOffers = lazy(() => import('containers/schoolOffers'));
// const StudentOffers = lazy(() => import('containers/studentOffers'));
// const ParentOffers = lazy(() => import('containers/parentOffers'));
// const Chunk = lazy(() => import('components/chunk'));

const IndexComponent = ({user={}}) => {
    const [navigate, setNavigate] = useState('');

    const [searchParam] = useSearchParams();
    const nav = useNavigate();


    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav])
    return(
        <>
        <Suspense fallback={<Loader />}>
            <div className='disp-flex'>
                <div className='flex-100'>
                    <div className='disp-block align-center'>
                        <div className='disp-block'>
                            <img src={voskoolLogo} className='voskool-logo' alt="Voskool" />
                        </div>
                        {/* <span className='disp-block font-bigger fw-600 app-text-color padd-4px align-center'>Launch Countdown Timer</span> */}
                    </div>
                    {/* <div className='disp-block align-center'>
                        <CountDownTimer end={new Date(LAUNCH_DATE)} style={{background:'#000', color:'#eee'}} font_item='font-slightly-small' font_item_number='font-big' />
                    </div>
                    <div className='disp-block' style={{padding:'10px'}}>
                        <span className='font-small fw-600' style={{color:'#000'}}>Voskool will be launching soon and to celebrate our pre-launch, We are offering a whooping <span className="red">100% discount</span> to the first twenty (20) schools to join us. So join us now to save all!  </span>

                    </div> */}
                </div>
            </div>
            {searchParam.get('t') ? (
                <>
                <div style={{width:'100%'}}>
                    <Cart user={user} type={searchParam.get('t')} />
                </div>
                </>
            ) : (
                <>
                <PlansAndPricing />
                {/* <Footer style={{background:'var(--voskoolBagColor)', borderRadius:'0px'}}>
                    <div className="flex-100">
                        <div className='disp-block align-center mr-3px'>
                            <span className='fw-600 font-slightly-small' style={{color:'#ddd'}}>Voskool is a revolutionary way of which your school can be interacted with to reduce hassle and give you a seamless experience.</span>
                        </div>
                        <span className="disp-block font-big padd-4px app-opp-text-color fw-600" style={{marginTop:'7px'}}>NEED HELP, CONTACT US NOW!</span>
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-paper-plane"></i>
                            </div>
                            <div  className="padd-4px">
                                <Link to="mailto:support@voskool.com" className="font-small fw-500" style={{color:'#ddd'}}>support@voskool.com</Link>
                            </div>
                        </div>
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-phone"></i>
                            </div>
                            <div  className="padd-4px">
                                <span className="font-small fw-500" style={{color:'#ddd'}}>{phoneDivider('+2348127404244')}</span>
                            </div>
                        </div>
                    </div>
                </Footer> */}
                </>
            )}
        <SchoolAppStyle />
        </Suspense>
        </>
    )
}

const Voskool = ({state}) => {
    const [user, setUser] = useState(state);

    const [searchParam] = useSearchParams();

    useEffect(() => {
        setUser(state);
    }, [state]);

    useEffect(() => {
        const local_storage = searchParam.get('act') ? {access:searchParam.get('act'), refresh:searchParam.get('rft')} : {access:'', refresh:''}
        if(local_storage.access && !localStorage.getItem('access')){
            localStorage.removeItem('access');
            localStorage.setItem('access', JSON.stringify(local_storage));
            window.location.reload();
        }
    }, [searchParam])

    return(
        <>  
            <Routes>
                <Route path="/" element={<IndexComponent user={user.data} />} />
                <Route path="/login" element={<LoginPage user={user.data} is_close={false} />} />
                {!user.isAuthenticated && (
                    <>
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/reset_password" element={<PasswordResetConfirmationPage />} />
                        <Route path="/activate/:uid/:token" element={<ActivateUserPage />} />
                        <Route path="/password/reset/:uid/:token" element={<PasswordResetPage />} />
                    </>
                ) }
                <Route path="/register" element={<CreateSchoolAccount user={user.data} />} />
                <Route path="/terms_of_service" element={<TermsOfService />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            </Routes>
            <GlobalStyle />
        </>
    )
}

export default Voskool;