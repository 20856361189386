import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, ContentBg, Content } from "./style";
import { default as icon } from "images/boy.png";
import { default as voskool } from "images/voskool_logo.png";

const VoWrapper = ({children, back="/", page="Sign Up", page_under='For Ease Of Access and Security, Sign Up Now...',
 img, steps=true, action="Register", data=[{name:'User Select...', done:false}, {name:'Sign Up', done:false}]}) => {
    const stepInsert = data.map((item, i) => {
        return(
            <div className="wrap brb" key={i}>
                <div className="title">
                    <span className="text">{item.name}</span>
                </div>
                <div className="icon">
                    {item.done ? (<i className="fas fa-check"></i>) : (<i className="fas fa-times"></i>)}
                </div>
            </div>
        )
    })
    return(
        <Wrapper>
            <ContentBg>
                <div className="header">
                    <i className="fas fa-angle-right"></i><span className="span">{`${page} Page`}</span>
                    <span  className="under">{page_under}</span>
                </div>
                <div className="header-img">
                    <img src={icon} alt={page} />
                </div>
            </ContentBg>
            <Content initial={{opacity: 0, y:'5vh'}} animate={{transition: {
                duration: 0.5,
                type: "spring",
                damping: 50,
                stiffness: 500,
            }, opacity: 1, y:'-5vh'}}>
                <div className={steps ? 'content-80' : 'content'}>
                    <div className="header">
                        <div className="btna">
                            {back && (
                                <Link to={back}>
                                    <i className="fas fa-angle-left"></i>Back
                                </Link>
                            )}
                            
                        </div>
                        <div className="trans">
                            
                            <h4 className="img_span">{action}</h4>
                        </div>
                        <div className="trans">
                            {img ? (<><img src={img} alt="Page" /><br /></>) : (<><img className="default" src={voskool} alt="Page" /><br /></>)}
                        </div>
                    </div>
                    <div className="children">
                        {children}
                    </div>
                    
                </div>
                {steps && (
                    <div className="content-20">
                        <div className="wrap">
                            <div className="title-h">
                                <span className="heading">STEPS</span>
                            </div>
                            <div className="icon">
                                <i className="fas fa-align-left heading"></i>
                            </div>
                        </div>
                        {stepInsert}
                    </div>
                )}
                
            </Content>
        </Wrapper>
    )
}
export default VoWrapper;