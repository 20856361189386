import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";
import Voskool from 'appContainer/voskool';
import { LOCATION } from "actions/location_handler";
import { checkUserOnline } from "components/app/school/actions/checkUserOnline";

import { HeadComponent } from "components/head";


const initialUserState = {
  email: "",
  first_name: "",
  id: 0,
  last_name: "",
  phone_number: "",
  gender: "",
  user_type: null,
}
const initalLayoutState = {
  "type":"",
  "data": initialUserState,
  "isAuthenticated":true,
};

const App = () => {
  
    const [user, setUser] = useState(initalLayoutState);
    const [loading, setLoading] = useState(true);

    const fetchUser =  useCallback(async () => {
        setLoading(true);
        await authHandler.load_user(load_user()).then(result => {
            setUser({...initalLayoutState, ...result});
            setLoading(false); 
        }).catch(error => {
            setLoading(false);
            setUser({...initalLayoutState, ...error.response.data});
        })
    }, []);
    useEffect(() => {
      fetchUser();
      LOCATION();
    }, [fetchUser]);
    useEffect(() => {
      if(user.data.id){
        const intval = setInterval(() => {
            checkUserOnline(user.data.id);
        }, 1000 * 60)
        return () => {
          clearInterval(intval);
        }
      }
  }, [user.data])
  useEffect(() => {
    if(loading === false){
      checkUserOnline(user.data.id);
    }
  }, [user.data, loading]);

  return (
    <>
    {!loading && (
      <Router>  
      <HeadComponent title="Voskool" /> 
        <Voskool state={user} />
    </Router>  
    )}
      
    </>
  );
}

export default App;
