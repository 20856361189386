import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 40px 0px;
    padding: 10px 20px;

    img.voskool-logo{
        width: 200px;
        height: 50px;

        @media (max-width: 1280px) {
            width: 200px;
            height: 40px;
        }
        @media (max-width: 780px) {
            width: 150px;
            height: 40px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 40px;
    .chunk-content{
        padding: 0px 10px;
        @media(max-width: 540px){
            padding: 0px 5px;
        }
        div{
            padding-top: 5px;
            a{
                margin-top: 5px;
                text-decoration: none;
                color: #ccc;
                font-size: .9rem;
                font-weight: 600;
                padding: 5px 15px;
                border-radius: 20px;
                border: 1px solid #fc1181;
                background: #fc1181;
                transition: .3s ease;

                @media(max-width: 540px){
                    font-size: .75rem;
                }
                :hover{
                    background: transparent;
                    color: #888;
                }
            }
        }
        p{
            color: #666;
            padding-left: 10px;
            letter-spacing: .7px;
            font-weight: 500;

            @media(max-width: 780px){
                padding-left: 7px;
            }
        }
        .inner-content{       
            .inner-header{
                h4{
                    letter-spacing: .8px;
                    color: #000;
                    font-size: 1.1rem;
                    font-weight: 800;
                    text-transform: uppercase;

                    @media(max-width: 780px){
                        font-size: 1rem;
                    }
                    @media(max-width: 540px){
                        font-size: .9rem;
                    }
                }
            }
            .inner-cont{
                display: flex;
                flex-wrap: wrap;

                .cont{
                    flex: 33.33%;
                    max-width: 33.33%;
                    margin-bottom: 10px;
                    padding: 5px;
                    border-bottom: 1px solid #444;
                    border-radius: 5px;
                    text-align: center;
                    background: transparent;
                    
                    
                    @media(max-width: 1280px){
                        flex: 50%;
                        max-width: 50%;
                    }
                    @media(max-width: 540px){
                        flex: 100%;
                        max-width: 100%;
                        text-align: left;
                    }
                    span{
                        display: inline-block;
                        font-weight: 600;
                        color: #555;
                        font-size: .9rem;
                        text-transform: capitalize;
                        letter-spacing: .5px;

                        @media(max-width: 1280px){
                            font-size: .8rem;
                        }
                        .icon{
                            padding-right: 8px;
                            font-size: 1.3rem;
                            color: #000;

                            @media(max-width: 1280px){
                                font-size: 1.2rem;
                            }
                        }
                        .iconq{
                            margin-left: 3px;
                            font-size: .6rem;
                            padding: 3px 5px;
                            border: 1px solid #ccc;
                            background: var(--voskoolBagColor);
                            color: #555;
                            border-radius: 50%;
                            @media(max-width: 1280px){
                                padding: 3px;
                                font-size: .5rem;
                            }
                        }
                    }
                }
            }
            
        }
    }
    img.chunk-resp-img{
        width: 180px;
        height: 120px;
        border-radius: 10px;
    }
    .img-cont{
        width: 100%;
        height: 100%;
        margin-top: 20px;
        margin-left: 5px;
        img{
            width: 100%;
            height: 190px;
            padding: 4px;
            border-radius: 10px;
            @media(max-width: 780px){
                padding: 4px;
                height: 190px;
                transform: translateY(30%);
            }
            @media(max-width: 540px){
                height: 180px;
                transform: translateY(30%);
            }
        }
    }
`;