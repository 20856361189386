import styled from "styled-components";
//fc5185 pink
export const InnerContent = styled.div`
    display: flex;
    -webkit-display: flex;
    -moz-display: flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);

    .flex-left{
        width: 40%;
        padding: 5px 15px;
        @media(max-width: 800px){
            width: 50%;
        }
        @media(max-width: 500px){
            padding: 5px;
        }
        .item-header{
            padding:0;
            @media(max-width: 800px){
                padding-bottom: 35px;
            }
            @media(max-width: 700px){
                padding-bottom: 56px;
            }
            @media(max-width: 320px){
                padding-bottom: 134px;
            }
            
            h2{
                font-size: 1.8rem;
                letter-spacing: .8px;
                color: var(--voskoolBagColor);

                @media(max-width: 1280px){
                font-size: 1.5rem;
                }
                @media(max-width: 800px){
                    font-size: 1.2rem;
                }
                @media(max-width: 320px){
                    font-size: .8rem;
                }
            }
           
        }
        .item-cont{
            border-top: 2px solid #ddd;
            padding: 20px 5px;
            @media(max-width: 500px){
                padding: 10px 2px;
            }

            .item{
                margin-bottom: 26.5px;
                border-bottom: 1px solid #ddd;
                @media(max-width: 500px){
                    margin-bottom: 21px;
                }
                @media(max-width: 320px){
                    margin-bottom: 16px;
                }
                span{
                    
                    font-size: 1rem;
                    font-weight: 600;
                    color: var(--voskoolBagColor);
                    text-transform: capitalize;
                    @media(max-width: 1280px){
                        font-size: .8rem;
                    }
                    @media(max-width: 800px){
                        font-size: .6rem;
                    }
                    @media(max-width: 500px){
                        font-size: .55rem;
                    }
                    @media(max-width: 320px){
                        font-size: .4rem;
                    }
                    i{
                        font-size: .5rem;
                        padding: 5px;
                        border-radius: 50%;
                        background: var(--voskoolBagColor);
                        color: #eee;
                        opacity: .7;
                        @media(max-width: 800px){
                            font-size: .4rem;
                        }
                        @media(max-width: 320px){
                            font-size: .35rem;
                            padding: 3px;
                        }
                    }
                    
                    
                }
            }
        }
    }
    .flex-right{
        display: flex;
        flex-wrap: wrap;
        width: 60%;
        padding: 5px 15px;
        position: relative;
        
        @media(max-width: 800px){
            width: 50%;
        }
        @media(max-width: 500px){
            padding: 5px;
        }

        .border-right{
            border-right: 1px solid #ddd;
        }
        .border-left{
            border-left: 1px solid #ddd;
        }
        .small-item-header-cont{
            display: none;
            @media(max-width: 800px){
                position: absolute;
                top: 0;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .small-item-header{
                    padding: 5px 6px;

                    
                    span{
                        color: #333;
                        font-weight: 700;
                        padding: 5px;
                        font-size: .7rem;
                        border-bottom: 1px solid #ddd;
                        cursor: pointer;
                    }
                    span.active{
                        border-bottom: 1px solid var(--voskoolBagColor);
                    }
                }
            }
            @media(max-width: 375px){
                top: -15px;
                .small-item-header{
                    span{
                        padding: 3px;
                    }
                }
                
            }
        }
        .flex-33{
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 5px;
            text-align: center;
            @media(max-width: 800px){
                flex: 0 0 100%;
                max-width: 100%;
            }

            .item-header{
                @media(max-width: 800px){
                    margin-top: 38px;
                }
                @media(max-width: 700px){
                    margin-top: 60px;
                }
                @media(max-width: 320px){
                    margin-top: 120px;
                }
                /* @media(max-width: 280px){
                    margin-top: 121px;
                } */
                a{
                    padding: 10px 45px;
                    text-decoration: none;
                    margin-bottom: 5px;
                    color: #eee;
                    letter-spacing: .8px;
                    background: var(--voskoolBagColor);
                    font-size: .8rem;
                    border-radius: 20px;
                    transition: .5s ease;

                    @media(max-width: 1280px){
                        padding: 7px 35px;
                        font-size: .7rem;
                    }
                    @media(max-width: 700px){
                        padding: 7px 35px;
                        font-size: .6rem;
                    }
                    @media(max-width: 320px){
                        padding: 7px 25px;
                        font-size: .5rem;
                    }
                   
                }
                a:hover{
                    opacity: .9;
                }
                h4{
                    color: #333;
                    font-weight: 700;
                    font-size: 1rem;
                    @media(max-width: 1280px){
                        font-size: .76rem;
                    }
                    @media(max-width: 800px){
                        font-size: .7rem;
                    }
                    @media(max-width: 320px){
                        font-size: .6rem;
                    }
                    span{
                        font-weight: 900;
                        color: var(--voskoolBagColor);
                        select{
                            background: transparent;
                            border: none;
                            color: #333;
                            font-weight: 500;
                            outline: none;
                            @media(max-width: 1280px){
                                font-size: .76rem;
                            }
                            @media(max-width: 800px){
                                font-size: .7rem;
                            }
                            @media(max-width: 320px){
                                font-size: .6rem;
                            }
                        }
                    }
                }
            }    
            .item-cont{
                padding: 20px 5px;
                border-top: 2px solid #ddd;
                border-radius: 10px;
                transition: .5s ease;
                -webkit-transition: .5s ease;
                -moz-transition: .5s ease;
                @media(max-width: 500px){
                    padding: 12px 2px;
                }
                
                a{
                    text-decoration: none;
                    .item{
                        margin-bottom: 27px;
                        border-bottom: 1px solid #ddd;

                        @media(max-width: 500px){
                            margin-bottom: 21.5px;
                        }
                        @media(max-width: 320px){
                            margin-bottom: 16.4px;
                        }
                        span{
                            color: #333;
                            font-weight: 500;
                            font-size: 1rem;
                            @media(max-width: 1280px){
                                font-size: .8rem;
                            }
                            @media(max-width: 800px){
                                font-size: .7rem;
                            }
                            @media(max-width: 320px){
                                font-size: .5rem;
                            }
                        }
                    }
                }
            }
            .item-cont:hover{
                background: var(--voskoolBagColor);
                a{
                    .item{
                        border-bottom: none;
                        span{
                            color: #ccc;
                        }
                    }
                }
            }
        }
    }
`;