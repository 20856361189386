import React from "react";
import { Wrapper, Content } from "./style";
import homeImg from "images/home_logo.jpg";
import parse from 'html-react-parser';
const Chunk = ({children, img=homeImg, alt="Parent", title="", desc="", is_put_img=false, is_put_img_right=false, img_top_resp=false}) => {
    return(
        <Wrapper>
           <Content>
               {is_put_img && (
                   <>
                   {img_top_resp && (
                        <div className='flex-100 chunk-disp-none'>
                                <div className="align-center">
                                        <img className="chunk-resp-img" src={img} alt={alt} />
                                </div>
                            </div>
                    )}
                   </>
               )}
               {is_put_img && (
                   <>
                   {!is_put_img_right && (
                       <div className={`flex-30-chunk small-320-disp-none ${img_top_resp && 'chunk-resp-disp-none'}`}>
                        <div className="img-cont">
                            <img src={img} alt={alt} />
                            </div>
                    </div>
                   )}
                   </>
               )}
               
               <div className={`${!img_top_resp ? `${!is_put_img ? 'flex-100' : 'flex-70-chunk'} `: 'flex-70-chunk-img-top-resp'}`}>
                   {title && (
                       <div className="align-center">
                        <h2 className="flex-chunk-header"> {title} </h2>
                    </div>  
                   )}
                    <div className="chunk-content">
                        {desc && (
                        <p className="font-small">{parse(desc)}</p>
                        )}
                        {children}
                        {/* <div className="align-right">
                            <Link to="/">Enroll Your Child Now</Link>
                        </div> */}

                        {/* <div className="inner-content">
                            <div className="inner-header">
                                <h4>Voskool Features:</h4>
                            </div>    
                            <div className="inner-cont">
                                <div className="cont">
                                    <span> <i class="fas fa-th-list icon"></i>Child's Activity <div className="menu-cont"><i className="fas fa-question iconq"></i>
                                    <div className="menu">
                                        <p>The total number of staff, parent and other users that you can create!</p>
                                    </div></div></span>
                                </div>
                                <div className="cont">
                                <span> <i className="fas fa-times icon"></i>Child's Activity <div className="menu-cont"><i className="fas fa-question iconq"></i>
                                    <div className="menu">
                                        <p>The total number of staff, parent and other users that you can create!</p>
                                    </div></div></span>
                                </div>
                                <div className="cont">
                                <span> <i className="fas fa-times icon"></i>Child's Activity <div className="menu-cont"><i className="fas fa-question iconq"></i>
                                    <div className="menu">
                                        <p>The total number of staff, parent and other users that you can create!</p>
                                    </div></div></span>
                                </div>
                                
                            </div> */}
                            {/* <div className="flex-100 align-center">
                                <button className="read-card-btn">See all featues <i className="fas fa-arrow-down"></i></button>
                            </div> */}
                        {/* </div> */}
                    </div>

               </div>
               {is_put_img && (
                   <>
                   {is_put_img_right && (
                       <div className={`flex-30-chunk small-320-disp-none ${img_top_resp && 'chunk-resp-disp-none'}`}>
                        <div className="img-cont">
                            <img src={img} alt={alt} />
                            </div>
                    </div>
                   )}
                   </>
               )}
               
            </Content> 
        </Wrapper>
    )
};
export default Chunk;