import React from "react";

import parse from 'html-react-parser';

import { HeadComponent } from "components/head";

const PrivacyPolicy = () => {
    const text = `
    <h1>Privacy Policy</h1>
    <p>This Privacy Policy governs the manner in which Voskool ("we," "our," or "us") collects, uses, maintains, and discloses information collected from users ("you" or "your") of our services. By using our services, you consent to the data practices outlined in this policy.</p>
    <h2>1. Information We Collect</h2>
    <p>We may collect personal and non-personal information from you when you use our services. The types of information we collect may include:</p>
    <ul>
        <li>Your name, email address, and contact information</li>
        <li>Usage data, such as your IP address, browser type, and device information</li>
        <li>Cookies and similar tracking technologies to enhance your user experience</li>
        <li>Any other information you voluntarily provide to us</li>
    </ul>
    <h2>2. How We Use Collected Information</h2>
    <p>We may use the information we collect for the following purposes:</p>
    <ul>
        <li>Provide and maintain our services</li>
        <li>Personalize your user experience</li>
        <li>Send periodic emails and updates related to our services</li>
        <li>Respond to your inquiries, questions, and feedback</li>
        <li>Improve our services, features, and functionality</li>
        <li>Monitor and analyze usage patterns and trends</li>
    </ul>
    <h2>3. Data Protection and Security</h2>
    <p>We implement appropriate data collection, storage, processing, and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our services.</p>
    <h2>4. Sharing Your Personal Information</h2>
    <p>We do not sell, trade, or rent your personal identification information to third parties. We may share generic, aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners and advertisers for the purposes outlined above.</p>
    <h2>5. Third-Party Websites and Services</h2>
    <p>Our services may contain links to third-party websites, applications, or services. These third-party sites have separate and independent privacy policies. We have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our services and welcome any feedback about these sites.</p>
    <h2>6. Compliance with Laws</h2>
    <p>We will disclose your personal information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our services.</p>
    <h2>8. Changes to this Privacy Policy</h2>
    <p>We reserve the right to update or revise this Privacy Policy at any time. We will post the revised date at the bottom of this page. We encourage you to review this policy periodically for any changes. Your continued use of our services after any modifications to this Privacy Policy constitutes your acceptance of those changes.</p>
    <h2>9. Contact Us</h2>
    <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at:</p>
    <address>
        Email: support@voskool.com<br>
        Telephone: 0812 740 4244<br>
    </address>
    <p>Last Updated: 21/07/2023</p>
    `;
    return(
        <>
        <HeadComponent title="Privacy Policy" />
        <div style={{padding:'20px'}}>
            {parse(text)}
        </div>
        </>
    )
}

export default PrivacyPolicy;