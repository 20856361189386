import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { Wrapper, Content } from "./style";

import { HashLink } from "react-router-hash-link";

import { convertNumber } from "components/app/actions/money";

const ChoosePlan = ({arr=[], feature_arr=[]}) => {
    const calcPlan = (mon=0, disct=0, type="month") => {
        // if(disct > 0){
        //     const val = parseInt((parseInt(mon) / 12) * (1 - (disct / 100)))
        //     return val;
        // }
        // const val = parseInt((parseInt(mon) / 12));
        // return val;
        if(disct > 0){
            const val = parseInt((parseInt(mon)) * (1 - (disct / 100)))
            return val;
        }
        const val = parseInt((parseInt(mon)));
        return val;
    }
    const actionType = (name="", type="") => {
        if(type === "Y"){
            return(
                <span><i className="fas fa-check green"></i></span>
            );
        }
        if(type === "M"){
            return(
                <span><i className="fas fa-check yellow"></i></span>
            );
        }
        if(type === "N"){
            return(
                <span><i className="fas fa-times red"></i></span>
            );
        }
        return(
            <span>{typeof name === "number" ? `N${convertNumber(name)}` : name}</span>
        );
    }
    return(
        <Fragment>
            {arr.map((item, i) => {
                return(
                    <Wrapper key={i}>
                        <Content>
                            {/* <div className=" med-1024-disp-visible arrow-select-right">
                                <i className="fas fa-angle-right ss" ></i>
                            </div>
                            <div className="med-1024-disp-visible arrow-select-left">
                                <i className="fas fa-angle-left ss"></i>
                            </div>
                            <div className="med-1024-disp-visible select-top">
                                <i className="ss-text">1/3</i>
                            </div> */}
                            <div className="card-header">
                                <span className="heading"><span className=" text-capitalize">{item.name}</span> <br /><i>{item.desc}</i></span>
                            </div>
                            <div className="card-content">
                                {item.discount && (
                                    <div className="price-item">
                                        <span className="old-price">{item.country.currency_symbol}{convertNumber(calcPlan(item.price))}</span> <span className="disct bg-red" style={{border:'none'}}>{item.discount}% Discount</span>
                                    </div>
                                )}
                                <div className="price-item">
                                    {item.discount === 100 ? (<span className="price">FREE</span>) : (<><span className="curr">{item.country.currency_symbol}</span><span className="price">{convertNumber(calcPlan(item.price, item.discount))}</span><span className="price-time"></span></>)}
                                </div>
                                <div className="price-item">
                                    <div className="item-cont">
                                        <Link to={`/?t=${item.name}`}>Select</Link>
                                    </div>
                                </div>
                                <div className="price-item">
                                    <span className="span">{item.country.currency_symbol}{convertNumber(calcPlan(item.renewal_price))}/mon, when you renew</span>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="item-header">
                                    <span> Features </span>
                                </div>
                                <div className="item-content">
                                    {feature_arr.slice(0, 5).map((item, j) => {
                                        return(
                                            <Fragment key={j}>
                                                <div className="flex-90">
                                                    {actionType('', item.act[i].type)} <span className="text">{item.act[i].name} {item.name}</span>
                                                </div>   
                                                <div className="flex-10">
                                                    {item.desc && (
                                                        <div className="menu-cont"><span className="fas fa-question quest-icon"></span>
                                                        <div className="menu left-menu right-tooltip">
                                                            <p>{item.desc}</p>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>   
                                            </Fragment>
                                        )
                                    })}
                                    
                                    
                                    <div className="flex-100 align-center">
                                        <HashLink to="#plan_detail" className="read-card-btn">See all featues <i className="fas fa-arrow-down"></i></HashLink>
                                    </div>    
                                </div>
                            </div>
                        </Content>
                    </Wrapper>
                )
            })}
        </Fragment>    
    );
}

export default ChoosePlan;