import React, {useState, useEffect} from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { HeadComponent } from "components/head";
import { activate_user } from "actions/auth";
import { Wrapper } from "./style";
import Background from "components/Background";

import voskool_logo from "images/voskool_logo.png";
import CenteredPage from "components/app/centeredPage";
import Loader from "components/app/loader";

const ActivateUserPage = () => {
    const [navigate, setNavigate] = useState('');
    const [isRedirect, setIsRedirect] = useState(false);
    const [isVerified, setIsVerified] = useState(true);
    const [loading, setLoading] = useState(true);
    const {uid, token} = useParams();

    const nav = useNavigate();

    useEffect(() => {
        if(isRedirect){
            setIsRedirect(false);
            setNavigate('/login');
        }
    }, [isRedirect])
    useEffect(() => {
        if(uid && token && loading){
            setLoading(true);
            activate_user(uid, token).then(() => {
                setIsVerified(true);
                setLoading(false);
                setTimeout(() => {
                    setIsRedirect(true);
                }, 5000);
            }).catch(e => {
                setIsVerified(false);
                setIsRedirect(false);
                setLoading(false);
            })
        }
    }, [uid, token, loading]);
    useEffect(() => {
        if(navigate){
            setNavigate('');
            nav(navigate);
        }
    }, [nav, navigate])

    return(
        <>
            <HeadComponent title="Verify Your Account" />
            {loading && <Loader />}
            <Background bg_color="#26223a">
                <Wrapper>
                    
                    <CenteredPage type="small" bg_style={{background:"#eed"}}>
                    <div className="disp-block align-center">
                        <img style={{width:'150px', height:'50px'}} src={voskool_logo} alt="Voskool" />
                    </div>
                    {!loading && (
                        <>
                            {isVerified ? (
                                <div className="disp-block align-center" style={{marginTop:'30px'}}>
                                    <h3 className="fw-600 app-text-color" style={{color:'green'}}>WELCOME!. Your account has been created successfully. Redirecting to login page...</h3>
                                    <div style={{marginTop:'20px'}}>
                                        <Link to="/"><i className="fas fa-arrow-left"></i> Go Back</Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="disp-block align-center" style={{marginTop:'30px'}}>
                                    <span className="font-small fw-600 app-text-color" >Something went wrong, please check back later, if your account has not been already created and have followed all due process</span>
                                    <div style={{marginTop:'20px'}}>
                                        <Link to="/"><i className="fas fa-arrow-left"></i> Go Back</Link>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    
                    {/* <div className="disp-block align-center" style={{marginTop:'15px'}}>
                        <button type="button" onClick={() => onActivateUser()} className="mybtn font-slightly-small hover-opacity" style={{background:'#23f', color:'#eee'}}>Verify Your Account Now!</button>
                    </div> */}
                </CenteredPage>
                </Wrapper>
            </Background>
        </>
    )
}
export default ActivateUserPage;