export const calcPlan = (mon=0, disct=0, month=12) => {
    if(disct > 0){
        const val = parseInt((parseInt(mon) / month) * (1 - (disct / 100)))
        return val;
    }
    const val = parseInt((parseInt(mon) / month));
    return val;
}
export const calcDisct = (mon=0, disct=0) => {
    const val = parseInt(mon) * (1 - (parseInt(disct)/100));
    return val;
}
export const calcDisctPrice = (mon=0, disct=0) => {
    const val = parseInt(mon) - calcDisct(mon, disct);
    return val;
}