import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { HeadComponent } from "components/head";

// import Grid from "components/grid";
// import CartCard from "./card";
import Chunk from 'components/chunk';
import Loader from "components/app/loader";
import PreloaderLine from "components/preloaderLine";

import { appServices } from "api/schoolApp/services";
import { Wrapper } from "./style";
import { HashLink } from "react-router-hash-link";
import { convertNumber } from "components/app/actions/money";
// import { convertDate } from "components/app/actions/dateConverter";
// import { addItemToArray } from "components/app/actions/array-utils/Add";
// import { updateArray } from "components/app/actions/array-utils/Update";
// import { calcPlan, calcDisct, calcDisctPrice } from "components/app/actions/utils/calculator";
import { calcDisct, calcDisctPrice } from "components/app/actions/utils/calculator";
import { Link } from "react-router-dom";
import { PAYSTACK_LIVE_KEY } from "actions/url";


const Cart = ({user={}, type=""}) => {
    const [state, setState] = useState({id:0, country:{id:0}});
    const [email, setEmail] = useState('');
    const [navigate, setNavigate] = useState('');
    const [loading, setLoading] = useState(false);
    const [preloadingLine, setPreloadingLine] = useState(true);
    const [successLoading, setSuccessLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorDes, setErrorDes] = useState('');
    const [allPlanPeriod, setAllPlanPeriod] = useState([]);
    const [config, setConfig] = useState({
        reference: '',
        email: user.email ? user.email : '',
        amount: 0,
        publicKey:PAYSTACK_LIVE_KEY
    })

    const [reload, setReload] = useState(true);


    const nav = useNavigate();
    const initializePayment = usePaystackPayment(config);

    const fetchAllPlan = (t="", id=0) => {
        setLoading(true);
        appServices.getAllVoskoolPlan().then(res => {
            const arr = res.data;
            appServices.insertRef({user:id}).then(res2 => {
                setPreloadingLine(false);
                setLoading(false);
                const filter = arr.filter((it) => it.name === t);
                if(filter.length > 0){
                    setState({...filter[0]});
                    setConfig((prev) => typeof prev === "object" ? {...prev, reference:res2.data.ref_id, amount:Math.floor(calcDisct(parseInt(filter[0].price)*100, filter[0].discount))} : {...prev});
                    setAllPlanPeriod([{type:6, price:filter[0].price_six, renewal_price:filter[0].renewal_price_six, num:0, is_active:false}, {type:12, price:filter[0].price, num:1, renewal_price:filter[0].renewal_price, is_active:true}])
                    return;
                }
                setError(true);
            }).catch(e => {
                appServices.getRef(id).then(res2 => {
                    setLoading(false);
                    setPreloadingLine(false);
                    const filter = arr.filter((it) => it.name === t);
                    if(filter.length > 0){
                        setState({...filter[0]});
                        setConfig((prev) => typeof prev === "object" ? {...prev, reference:res2.data.ref_id, amount:Math.floor(calcDisct(parseInt(filter[0].price)*100, filter[0].discount))} : {...prev});
                        setAllPlanPeriod([{type:6, price:filter[0].price_six, renewal_price:filter[0].renewal_price_six, num:0, is_active:false}, {type:12, price:filter[0].price, num:1, renewal_price:filter[0].renewal_price, is_active:true}])
                        return;
                    }
                    setError(true);
                    
                }).catch(() => {
                    setLoading(false);
                    setPreloadingLine(false);
                    const filter = arr.filter((it) => it.name === t);
                    if(filter.length > 0){
                        setState({...filter[0]});
                        setAllPlanPeriod([{type:6, price:filter[0].price_six, renewal_price:filter[0].renewal_price_six, num:0, is_active:false}, {type:12, price:filter[0].price, num:1, renewal_price:filter[0].renewal_price, is_active:true}])
                        return;
                    }
                    setError(true);
                })
            })
            
        }).catch(e => {
            setLoading(false);
            setPreloadingLine(false);
            setError(true);
        })
    }
    
    const onCreateAccount = e => {
        e.preventDefault();
        setNavigate(`/signup?e=${email}`);
    }
    const onChangeEmail = (val) => {
        setEmail(val);
    }
    // const handleClick = (num=-1) => {
    //     setErrorDes('');
    //     setConfig((prev) => typeof prev === "object" ? {...prev, amount:Math.floor(calcDisct(parseInt(allPlanPeriod[num].price)*100, state.discount)), reference:user.email.slice(0, 3)+new Date().getTime()+user.email.slice(3, 4)+(Math.floor(Math.random()*500000)).toString()} : {...prev});
    //     setAllPlanPeriod(updateArray(addItemToArray(allPlanPeriod, {is_active:false} ), num, {...allPlanPeriod[num], is_active:true}))
    // }
    const onSuccess = (ref) => {
        setSuccessLoading(true);
        const activePeriod = allPlanPeriod.length > 0 ? allPlanPeriod.filter((it) => it.is_active === true)[0] : {type:'', price:0};
        appServices.paymentRegisterSchool({ref_id:ref.reference, amount_paid:config.amount, paid_by:user.id, plan:state.id, period:activePeriod.type, pay_gateway:'paystack'}).then((res) => {
            setSuccessLoading(false);
            setNavigate(`/register?type=success`);
        }).catch(e => {
            setSuccessLoading(false);
        })
    }
    const onClose = () => {
        console.log('closed');
    }
    const onInitializePayment = () => {
        setErrorDes('');
        setSuccessLoading(true);
        appServices.getSchoolPaymentCart(user.id).then(res => {
            setErrorDes('Payment Already Exists');
            setSuccessLoading(false);
            return;
        }).catch(e => {
            if(e.response.status === 401){
                setErrorDes('Error... Login To Begin Payment');
                setSuccessLoading(false);
                return;
            }
            if(e.response.status === 400){
                // if(state.discount === 100){
                   
                //     const activePeriod = allPlanPeriod.length > 0 ? allPlanPeriod.filter((it) => it.is_active === true)[0] : {type:'', price:0};
                //     const data = {ref_id:config.reference, amount_paid:0, paid_by:user.id, plan:state.id, period:activePeriod.type, pay_gateway:'none'}
                //     appServices.paymentRegisterSchool(data).then((res) => {
                //         setSuccessLoading(false);
                //         setNavigate(`/register?type=success`);
                //     }).catch(e => {
                //         setSuccessLoading(false);
                //         setErrorDes(e.response.data.detail ? e.response.data.detail : 'Something Went Wrong');
                //     })
                    
                //     return;
                // }
                initializePayment(onSuccess, onClose);
                return;
            }
            setErrorDes(e.response.data.detail ? e.response.data.detail : 'Something Went Wrong');
            return;
        })
        
    }
    useEffect(() => {
        if(reload){
            fetchAllPlan(type, user.id);
            setReload(false);
        }
    }, [type, reload, user]);

    useEffect(() => {
        if(navigate){
            setNavigate('');
            nav(navigate);
        }
    }, [nav, navigate])

    // const calcDatePeriod = (period=0) => {
    //     const curr_date = new Date().getTime();
    //     const period_date = (period / 12) * (1000 * 60 * 60 * 24 * 365);
    //     const add = curr_date + period_date;
    //     return new Date(add);
    // } 
    const activePeriod = allPlanPeriod.length > 0 ? allPlanPeriod.filter((it) => it.is_active === true)[0] : {type:'', price:0};
    return(
        <>
        {preloadingLine && <PreloaderLine />}
        {!loading && (
            <>
            {!error ? (
                <>
                    <HeadComponent title="Cart" />
                    
                    {successLoading && <Loader />}
                    <Wrapper>
                        <div className="disp-block">
                            <div className="flex-100 disp-block">
                                <span className="font-super-big fw-700" style={{color:'var(--voskoolBagColor)'}}>Almost there! Complete Your Order Now</span>
                            </div>
                            <div className="flex-100 disp-block" style={{marginTop:'8px'}}>
                                <span className="fw-500 font-small text-capitalize" style={{color:'#000'}}>Selected Plan: <b>{state.name} School</b></span>
                            </div>
                        </div>
                        {/* <div className="disp-block">
                            <div className="disp-flex wrap">
                                <div className="flex-100" style={{margin:'20px 0 100px'}}>
                                    <div className="disp-block" style={{margin:'20px 0'}}>
                                        <span className="font-super-big fw-600" style={{color:'var(--voskoolBagColor)'}}>1. Choose A Period</span>
                                    </div>
                                    <Grid grid_width={"small-w"} style={{marginBottom:'20px'}}>
                                        {allPlanPeriod.map((item, i) => {
                                            return(
                                                <Fragment key={i}>
                                                    <CartCard onClick={() => handleClick(item.num)} period={item.type} base_period={allPlanPeriod[0].type} discount={state.discount} base_price={allPlanPeriod[0].price} price={calcPlan(item.price, state.discount, item.type)} prev_price={calcPlan(item.price, 0, item.type)} renewal_price={calcPlan(item.renewal_price, 0, item.type)} curr={state.country.currency_symbol} renewal_date={convertDate(calcDatePeriod(item.type), 'ddMMyyyy')} is_active={item.is_active} />
                                                </Fragment>
                                            )
                                        })}
                                    </Grid>
                                    <div className="disp-flex wrap">
                                        <div className="flex-100 align-right">
                                            <HashLink to={!user.id ? `?t=${type}#create_account` : `?t=${type}#make_payment`} className="btn">Next <i className="fas fa-arrow-right"></i></HashLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {!user.id && (
                            <div id='create_account'>
                            <div className="disp-flex" style={{marginBottom:'10px'}}>
                                <div className="flex-100">
                                    <div className="disp-block" style={{margin:'20px 0'}}>
                                        <span className="font-super-big fw-600" style={{color:'var(--voskoolBagColor)'}}>1. Create Your Account</span>
                                    </div>
                                    <div className="disp-block">
                                        <span className="fw-500 font-small" style={{color:'#000'}}>Already have an account? <HashLink to={`/login?red_url=/?t=${type}`} className="fw-600" style={{color:'blue'}}>Log In</HashLink></span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={e => onCreateAccount(e)}>
                                <div className=" wrap jc-sb" style={{display:'flex'}}>
                                    <div className="flex-50" style={{width:'100%', padding:'5px'}}>
                                        <input type="email" required className="form-control font-small " onChange={e => onChangeEmail(e.target.value)} value={email} placeholder="Email Address" />
                                    </div>
                                    <div className="flex-50 align-right" style={{padding:'5px'}}>
                                        <button type="submit" className="btn br bordered-blue font-slightly-small hover-opacity br-10" style={{background:'var(--voskoolBagColor)', color:'#ddd'}}>Create an account</button>
                                    </div>
                                </div>
                            </form>
                            <div className="disp-flex wrap" style={{marginTop:'20px'}}>
                                <div className="flex-100 align-right">
                                    <HashLink to={`?t=${type}#make_payment`} className="btn">Next <i className="fas fa-arrow-right"></i></HashLink>
                                </div>
                            </div>
                        </div>
                        )}
                        <div style={{ margin:'100px 0 20px'}} id="make_payment">
                            <div className="disp-flex" style={{marginBottom:'10px'}}>
                                <div className="flex-100">
                                    <div className="disp-block" style={{margin:'20px 0'}}>
                                        <span className="font-super-big fw-600" style={{color:'var(--voskoolBagColor)'}}>{user.id ? '1' : '2'}. Make Your Payment</span>
                                    </div>
                                </div>
                            </div>
                            <Chunk>
                                <div style={{padding:'15px'}}>
                                    <div className="wrap jc-sb" style={{display:'flex'}}>
                                        <div className="disp-block"><span className="font-med fw-700 text-capitalize" style={{color:'#000'}}>{type} School - 20 Years Plan</span></div>
                                        <div className="disp-block"><span className="font-big fw-500 text-capitalize" style={{color:'#000'}}>{state.country.currency_symbol}{parseInt(activePeriod.price) !== 0 ? convertNumber(parseInt(activePeriod.price)) : 'FREE'}</span></div>
                                    </div>
                                    <div className="wrap jc-sb" style={{display:'flex'}}>
                                        <div className="disp-block"><span className="font-med fw-500 text-capitalize" style={{color:'#000'}}>Taxes and fees</span></div>
                                        <div className="disp-block"><span className="font-big fw-500 text-capitalize" style={{color:'#000'}}>N0</span></div>
                                    </div>
                                    <div className="wrap jc-sb" style={{display:'flex'}}>
                                        <div className="disp-block"><span className="font-med fw-500 text-capitalize" style={{color:'#000'}}>Plan Discount {state.discount > 0 && `- ${state.discount}%`}</span></div>
                                        <div className="disp-block"><span className="font-big fw-500 text-capitalize" style={{color:state.discount > 0 ? '#2f4' : '#000'}}>{state.country.currency_symbol}{state.discount > 0 ? `-${convertNumber(calcDisctPrice(activePeriod.price, state.discount))}` : 0}</span></div>
                                    </div>
                                    <div className="wrap jc-sb" style={{display:'flex'}}>
                                        <div className="disp-block"><span className="font-bigger fw-600 text-capitalize" style={{color:'#000'}}>Total</span></div>
                                        <div className="disp-block"><span className="font-bigger fw-600 text-capitalize" style={{color:'#000'}}>{state.country.currency_symbol}{convertNumber(calcDisct(activePeriod.price, state.discount))}</span></div>
                                    </div>
                                    {errorDes && (
                                        <div className="disp-block align-center" style={{marginTop:'10px'}}>
                                            <span className="red fw-600 font-slightly-small">{errorDes}</span>
                                            {errorDes.toLowerCase()  === "payment already exists" && <div className="disp-block"><Link to="/register">Begin School Registration</Link></div>}
                                        </div>
                                    )}
                                    <div className="disp-block align-center">
                                        <button type="button" className="mybtn font-small fw-700" onClick={() => {onInitializePayment()}} style={{background:'#3225ff', color:'#ccc'}}>Begin Secure Payment</button>
                                    </div>
                                    <div className="disp-block" style={{marginTop:'20px'}}>
                                        <span className="font-slightly-small fw-500" style={{color:'#000'}}>By checking out, you agree to our <HashLink to="/terms_of_service" target={"_blank"} style={{background:'none', color:'#00f', border:'none'}}>Terms Of Service</HashLink> and our <HashLink to="/privacy_policy" target={"_blank"} style={{background:'none', color:'#00f', border:'none'}}>Privacy Policy.</HashLink>  You can cancel recurring payments anytime</span>
                                    </div>
                                </div>
                            </Chunk>
                        </div>
                    </Wrapper>
                </>
            ) : (
                <>
                <div className="disp-block align-center">
                    <h1 style={{color:'red'}}> 404 Error | Page Not Found</h1>
                    <HashLink to="/" className="font-big "> <i className="fas fa-arrow-left"></i> Go Back</HashLink>
                </div>
                </>
            )}
            </>
        )}
        </>
    )
}

export default Cart;