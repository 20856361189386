import { nth } from "./nth";
import parse from 'html-react-parser';
export const convertDate = (d=null, type) => {
    if (d){
        Date.prototype.getMonthName = function() {
            var monthNames = [ "Jan", "Feb", "March", "April", "May", "June", 
                               "July", "Aug", "Sept", "Oct", "Nov", "Dec" ];
            return monthNames[this.getMonth()];
        }
        const date = new Date(d);
        var new_month;
        var new_day;
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const day = date.getDate();

        if(month > 9){
            new_month = month;
        }
        else{
            new_month = '0'+month;
        }
        if(day > 9){
            new_day = day;
        }
        else{
            new_day = '0'+day;
        }
        if(type === "yyyy-mm-dd"){
            return[ year, new_month, new_day ].join('-');
        }
        else if(type==="yyyy"){
            return year;
        }
        else if(type==="ddMMyyyy"){
            return parse(`${nth(new_day)} ${[date.getMonthName(), year].join(', ').trimEnd(', ')}`);
        }
        else if(type === "ddMM"){
            return parse(`${nth(new_day)} ${date.getMonthName()}`);
        }
    }
    else{
        return null;
    }
}

