import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Wrapper, Content } from "components/signUpPage/style";
import { motion } from 'framer-motion';

import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { HeadComponent } from 'components/head';
// import CountDownTimer from 'components/app/countDownTimer';
import TABLE from 'components/app/table_html/table';
import TR from 'components/app/table_html/tr';
import TH from 'components/app/table_html/th';
import TD from 'components/app/table_html/td';
import { NotificationWrapper } from 'components/app/notification/style';
import Notification from 'components/app/notification';
import PreloaderLine from 'components/preloaderLine';
import Loader from 'components/app/loader';
import { appServices, appServices5 } from 'api/schoolApp/services';
import { validateSchoolRegistrationForm, validateSchoolBranchForm, validateClassSectionForm, validateSchoolSessionForm } from 'components/app/actions/validation';
import { toBoolean } from 'components/app/actions/toBoolean';
// import { LAUNCH_DATE } from 'actions/url';
//import { getFormData } from 'components/app/actions/array-utils/Form';
import { STATES } from "api/voskool/services/countries";
import { addItemToArray } from 'components/app/actions/array-utils/Add';
import { updateArray } from 'components/app/actions/array-utils/Update';
import { convertDate } from 'components/app/actions/dateConverter';
//import Note from 'components/app/NOTE';
import { Link } from 'react-router-dom';
import Note from 'components/app/NOTE';

let vod = addItemToArray([{name:'School Registration', done:false}, {name:'Create School Branch', done:false}, {name:'Create Class Sections', done:false}, {name:'Create Classes', done:false}, {name:'Finish...', done:false}]);
const CreateSchoolAccount = ({user}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialFormBranchState = useMemo(() => ({address:'', name:'', city:'', state:''}), []);
    const initialFormSessionState = useMemo(() => ({session:'', year:'', res_date:'', vac_date:''}), []);
    const initialFormUserState = useMemo(() => ({phone_code:'', phone:'', title:'', name:'', email:'', use_phone_exist:false, use_email_exist:true, country:'', motto:'', icon:null, address:''}), []);

    const [formUser, setFormUser] = useState(initialFormUserState);
    const [formUserSession, setFormUserSession] = useState(initialFormSessionState);
    const [formUserBranch, setFormUserBranch] = useState(initialFormBranchState);
    const [formClassSectionError, setFormClassSectionError] = useState({});
    const [formUserError, setFormUserError] = useState({});
    const [formUserBranchError, setFormUserBranchError] = useState({});
    const [formUserSessionError, setFormUserSessionError] = useState({});
    const [state, setState] = useState({id:0});
    const [schoolState, setSchoolState] = useState({id:0})

    
    const [st, setSt] = useState([]);
    const [allSchoolBranch, setAllSchoolBranch] = useState([]);
    const [allClassSection, setAllClassSection] = useState([]);
    const [allOrigClasses, setAllOrigClasses] = useState([]);
    const [allClasses, setAllClasses] = useState([]);
    const [allTerm, setAllTerm] = useState([]);
    const [allClassSectionSchool, setAllClassSectionSchool] = useState([]);
    const [allSession, setAllSession] = useState({id:0});
    const [classArr, setClassArr] = useState([]);
    const [voData, setVoData] = useState(vod);

    const [step, setStep] = useState(0);
    const [navigate, setNavigate] = useState('');
    const [submit, setSubmit] = useState('');

    const [loading, setLoading] = useState(false);
    const [preloadingLine, setPreloadingLine] = useState(true);
    const [reload, setReload] = useState(true);
    const [preloading, setPreloading] = useState(true);
    const [nextify, setNextify] = useState(true);
    const [isNextifyClass, setIsNextifyClass] = useState(false);
    const [nextifyPos, setNextifyPos] = useState(0);
    const [totClasses, setTotClasses] = useState(0);
    const [classIndex, setClassIndex] = useState(0);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const nav = useNavigate();
    const [searchParams] = useSearchParams();

    const getNextifyPosition = (data={}, pos=-1) => {
        if(pos > -1){
            return pos;
        }
        if(data.is_setup_app){
            return 5;
        }
        if(data.is_setup_branch && data.is_setup_class && data.curr_session){
            return 4;
        }
        if(data.curr_session){
            return 3;
        }
        if(data.is_setup_branch){
            return 2;
        }
        if(data.id){
            return 1;
        }
        return 0;
    }
    const addArrayFromNum = (num=0, name="") => {
        let data = [];
        for(let i = 0; i < num; i++){
            const j = i + 1;
            data.push({name:`${name} ${j}`, num:i, is_checked:false});
        }
        return data;
    }

    const fetchCart = useCallback((id, type=false, is_trial=false) => {
        setLoading(true);
        setPreloadingLine(true);
        setPreloading(true);
        appServices.getSchoolPaymentCart(id).then(res => {
            setState(res.data);
            appServices.getRegisteredSchool(id).then(res2 => {
                setSchoolState(res2.data);
                setNextify(true);
                setPreloading(false);
                setPreloadingLine(false);
                setNextifyPos(getNextifyPosition(res2.data));
                setLoading(false);
            }).catch(e => {
                setSchoolState({id:0});
                setLoading(false);
                setPreloadingLine(false);
                setPreloading(false);
            })
            if(type){
                setSuccess({title:'Payment Successful', text:'Your payment was successful, register your school now'});
            }
        }).catch(e => {
            if(is_trial === "true" || is_trial === true){
                appServices.getSchoolTrialDomain().then(res => {
                    setState({...res.data, is_trial:true, is_new:true});
                    appServices.getRegisteredSchool(id).then(res2 => {
                        setSchoolState(res2.data);
                        setNextify(true);
                        setPreloading(false);
                        setPreloadingLine(false);
                        setNextifyPos(getNextifyPosition(res2.data));
                        setLoading(false);
                    }).catch(e => {
                        setSchoolState({id:0});
                        setLoading(false);
                        setPreloadingLine(false);
                        setPreloading(false);
                    })
                }).catch(e => {
                    appServices.createSchoolTrialDomain().then(res => {
                        setState({...res.data, is_trial:true, is_new:true});
                        appServices.getRegisteredSchool(id).then(res2 => {
                            setSchoolState(res2.data);
                            setNextify(true);
                            setPreloading(false);
                            setPreloadingLine(false);
                            setNextifyPos(getNextifyPosition(res2.data));
                            setLoading(false);
                        }).catch(e => {
                            setSchoolState({id:0});
                            setLoading(false);
                            setPreloadingLine(false);
                            setPreloading(false);
                        })
                    }).catch(e => {
                        setNavigate(`/login?red_url=/register?is_trial=true`);
                    })
                })
                return;
            }
            setNavigate('/');
            
        })
    }, []);
    const fetchAllState = (id) => {
        STATES(id).then(res => {
            setSt(res.data);
        }).catch(e => {
            setSt([]);
        })
    }
    const fetchAllSchoolBranch = (sname, sid) => {
        setLoading(true);
        setError({data:[], title:''});
        appServices.getAllSchoolBranch_(sname, sid).then(res => {
            setAllSchoolBranch(res.data);
            setLoading(false);
        }).catch(e => {
            setAllSchoolBranch([]);
            setLoading(false);
        })
    }
    const fetchAllClassSection = (sid) => {
        setError({data:[], title:''});
        setLoading(true);
        appServices.getAllClassSection(sid).then(res => {
            setAllClassSection(addItemToArray(res.data));
            setLoading(false);
        }).catch(e => {
            setAllClassSection([]);
            setLoading(false);
            setError({data:['Internal Server Error'], title:'Class Section Fetch Error'});
        })
    }
    const fetchAllClassSectionSchool = (sid) => {
        setError({data:[], title:''});
        setLoading(true);
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSectionSchool(addItemToArray(res.data, {name_edit:'', short_name_edit:'', is_save:false}));
            setLoading(false);
        }).catch(e => {
            setAllClassSectionSchool([]);
            setLoading(false);
            setError({data:['Internal Server Error'], title:'Class Section Fetch Error'});
        })
    }
    const fetchAllClasses = () => {
        appServices.getAllClassItem(1).then(res => {
            setAllOrigClasses(res.data);
        })
    }
    const fetchSession = (sid) => {
        setError({data:[], title:''});
        appServices5.getCurrentSession(sid).then(res => {
            setAllSession(res.data);
            setFormUserSession(res.data);
        }).catch(e => {
            // setError({data:['Internal Server Error'], title:'Session Data Fetch Error'});
            setAllSession({});
        })
    }
    const fetchAllClassesSchool = (sname, sid) => {
        setError({data:[], title:''});
        setLoading(true);
        appServices.getAllClassSchoolItem_(sname, sid).then(res => {
            setAllClasses(addItemToArray(res.data));
            setLoading(false);
        }).catch(e => {
            setError({data:['Internal Server Error'], title:'Class Data Fetch Error'});
            setAllClasses([]);
            setLoading(false);
        })
    }
    const fetchAllTerm = (school_id) => {
        appServices5.getAllTerm(school_id).then(res => {
            setAllTerm(res.data);
        })
    }
    const error_handler = (response={}) => {
        if(Array.isArray(response.title) && response.title.length > 0){
            return response.title[0];
        }
        if(response.detail){
            return response.detail;
        }
        return 'Something went wrong';
    }

    const onRegisterSchool = (e) => {
        e.preventDefault();
        setFormUserError(validateSchoolRegistrationForm(formUser));
        setSubmit('register_school');
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "registered_school"){
            setLoading(true);
            setSubmit('');
            if(state.is_new && !state.is_used && !schoolState.id){
                const form_data = new FormData();
                form_data.append('name', formUser.name);
                form_data.append('motto', formUser.motto);
                form_data.append('email', formUser.use_email_exist ? user.email : formUser.email);
                form_data.append('phone', formUser.use_phone_exist ? user.phone_number : `${formUser.phone_code}${formUser.phone}`);
                form_data.append('title', formUser.title);
                form_data.append('country', formUser.country);
                form_data.append('working_time_start', formUser.working_time_start);
                form_data.append('working_time_end', formUser.working_time_end);
                formUser.icon && form_data.append('icon', formUser.icon, formUser.icon.name);
                form_data.append('user', user.id);
                state.is_trial ? form_data.append('free_domain', state.id) : form_data.append('curr_pay', state.id);
                form_data.append('pri_color', formUser.pri_color)
                appServices.registerSchool(form_data).then(res => {
                    setSchoolState(res.data);
                    setNextify(true);
                    setNextifyPos(getNextifyPosition(res.data));
                    setFormUser(initialFormUserState);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    setError({data:[error_handler(e.response.data)], title:'Registration Error'});
                })
                return;
            }
            setLoading(false);
            setNextify(true);
            setNextifyPos(getNextifyPosition(schoolState));
            return;
        }
    }
    const onRegisterSchoolBranch = e => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setSubmit('insert_school_branch');
        setFormUserBranchError(validateSchoolBranchForm(formUserBranch));
        if(submit === "inserted_school_branch"){
            setLoading(true);
            const data = {
                school:schoolState.id,
                location: formUserBranch.address,
                is_active:true,
                state:formUserBranch.state,
                name: formUserBranch.name,
                created_by: user.id,
                city: formUserBranch.city
            }
            appServices.insertSchoolBranch_(schoolState.title,  data).then(res => {
                fetchAllSchoolBranch(schoolState.title, schoolState.id);
                setSuccess({title:'Success', text:'Branch Created Successfully'});
                setFormUserBranch(initialFormBranchState);
            }).catch(e => {
                setError({title:'Create Branch Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
                setLoading(false);
            })
        }
    }
    const onRegisterSchoolSession = (e) => {
        e.preventDefault();
        setSubmit('insert_school_session');
        setFormUserSessionError(validateSchoolSessionForm(formUserSession));
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_school_session"){
            setLoading(true);
            setSubmit('');
            const data = {
                school:schoolState.id,
                session: formUserSession.session,
                year: formUserSession.year,
                term: formUserSession.term,
                res_date:  convertDate(formUserSession.res_date, 'yyyy-mm-dd'),
                interview_start_date: formUserSession.interview_start_date ? convertDate(formUserSession.interview_start_date, 'yyyy-mm-dd') : '',
                interview_end_date: formUserSession.interview_end_date ? convertDate(formUserSession.interview_end_date, 'yyyy-mm-dd') : '',
                is_active:true,
                active_session:true,
                created_by: user.id
            }
            if(allSession.id){
                appServices.updateSchoolSession_(schoolState.title, schoolState.id, allSession.id, data).then(res => {
                    setAllSession(res.data);
                    setSuccess({title:'Success', text:'Session Created Successfully'});
                    setFormUserSession(res.data);
                    setSchoolState({...schoolState, curr_session:res.data.id})
                    setNextify(true);
                    setNextifyPos(getNextifyPosition({...schoolState, curr_session:res.data.id}));
                    setLoading(false);
                }).catch(e => {
                    setError({title:'Create Session Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
                    setLoading(false);
                    console.log(e.response.data)
                })
                return;
            }
            appServices.insertSchoolSession_(schoolState.title, data).then(res => {
                setAllSession(res.data);
                setSuccess({title:'Success', text:'Session Created Successfully'});
                setFormUserSession(res.data);
                setSchoolState({...schoolState, curr_session:res.data.id})
                setNextify(true);
                setNextifyPos(getNextifyPosition({...schoolState, curr_session:res.data.id}));
                setLoading(false);
            }).catch(e => {
                setError({title:'Create Session Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
                setLoading(false);
                console.log(e.response.data)
            })
            
        }
    }
    const onSetupBranch = () => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(allSchoolBranch.length > 0){
            setLoading(true);
            appServices.updateRegisteredSchool(schoolState.title, schoolState.id, {is_setup_branch:true}).then(res => {
                setLoading(false);
                setSuccess({title:'Success', text:'All School Branch Data Added Successfully'});
                setSchoolState(res.data);
                setNextify(true);
                setNextifyPos(getNextifyPosition(res.data));
            }).catch(e => {
                setError({title:'Branch Setup Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
                setLoading(false);
            })
        }
        setError({title:'Branch Setup Error', data:['No School Branch Data Found']});
        return;
    }
    // const onSetupSession = () => {
    //     setError(initialErrorState);
    //     setSuccess(initialSuccessState);
    //     if(allSession.id > 0){
    //         setLoading(true);
    //         appServices.updateRegisteredSchool(schoolState.title, schoolState.id, {curr_session:allSession.id}).then(res => {
    //             setLoading(false);
    //             setSuccess({title:'Success', text:'Session Activated Successfully'});
    //             setSchoolState(res.data);
    //             setNextify(true);
    //             setNextifyPos(getNextifyPosition(res.data));
    //         }).catch(e => {
    //             setError({title:'Branch Setup Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
    //             setLoading(false);
    //         })
    //     }
    //     setError({title:'Branch Setup Error', data:['No School Branch Data Found']});
    //     return;
    // }
    const onSetupClassSection = () => {
        const filter = allClassSectionSchool.filter((it) => it.is_save === false)
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(allClassSectionSchool.length === filter.length && allClassSectionSchool.length > 0){
            setLoading(false);
            setSuccess({title:'Success', text:'All Class Section Added Successfully'});
            setNextify(true);
            setClassIndex(0);
            setNextifyPos(4);
            return;
        }
        setError({title:'Class Section Error', data:['Error... Please Make Sure All Added Class Section Has Been Saved']})
        return;
    }
    const onStartCreateClass = (id, short_name="") => {
        setError(initialErrorState);
        const class_arr = allOrigClasses.filter((it) => it.class_section === id)
        if(totClasses > 0){
            if(totClasses <= class_arr.length){
                setIsNextifyClass(true);
                setClassArr(addArrayFromNum(totClasses, short_name));
                return;
            }
            setError({title:'Error', data:[`Total Number Cannot Be More Than ${class_arr.length}`]});
            return;
        }
        setError({title:'Error', data:[`Total Number Required`]});
        return;
        
    }
    const onBackToClassSection = (i) => {
        if(i < 1){
            setNextify(true);
            setNextifyPos(2);
            return;
        }
        const prev_class_section = allClassSectionSchool[i-1];
        const all_prev_class = allClasses.filter((it) => it.class_section_school===prev_class_section.id);
        setClassIndex(i-1); 
        setIsNextifyClass(true); 
        setTotClasses(all_prev_class.length);
        setClassArr(addArrayFromNum(all_prev_class.length, prev_class_section.short_name));
        setLoading(false);
        return;
    }
    const onToSession = () =>  {
        setNextify(true);
        setFormUser({...formUser, ...allSession});
        setNextifyPos(2)
    }
    const onBackToBranch = () => {
        setError(initialErrorState);
        setLoading(true);
        appServices.updateRegisteredSchool(schoolState.title, schoolState.id, {is_setup_branch:false}).then(res => {
            setLoading(false);
            setSchoolState(res.data);
            setNextify(true);
            setNextifyPos(1);
        }).catch(e => {
            setError({title:'Branch Setup Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
            setLoading(false);
        })
    }
    const onDeleteBranch = (id) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices.deleteSchoolBranchMain_(schoolState.title, id).then(res => {
            fetchAllSchoolBranch(schoolState.title, schoolState.id);
            setSuccess({title:'Success', text:'Data Deleted Successfully'});
        }).catch(e => {
            setError({title:'Delete Branch Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
            setLoading(false);
        })
    }
    const onSaveClassSection = (e, num=-1, item={}, cnum=-1) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(Object.keys(validateClassSectionForm(item)).length === 0) {
            setLoading(true);
            setFormClassSectionError({});
            const data = {name:item.edit_name ? item.edit_name : item.name, short_name: item.edit_short_name ? item.edit_short_name : item.short_name}
            appServices.updateClassSectionSchool_(schoolState.title, item.id, data).then(res => {
                setLoading(false);
                setSuccess({title:'Success', text:'Class Section Saved Successfully'});
                setAllClassSectionSchool(updateArray(allClassSectionSchool, num, {...allClassSectionSchool[num], is_save:false}));
            }).catch(e => {
                setLoading(false);
                setError({title:'Class Section Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
            })
            return;
        }
        setFormClassSectionError({num:num, ...validateClassSectionForm(item)});
        return;
    }
    const onClickCheck = (id, item={}) => {
        setLoading(true);
        setError(initialErrorState);
        const firstLetter = (arr=[]) => {
            let data = '';
            for(let i = 0; i < arr.length; i++){
                data = `${data}${arr[i][0].toUpperCase()}`;
            }
            return data;
        }
        const short_name_spl = item.class_section.split(' ');
        const short_name = short_name_spl.length > 1 ? firstLetter(short_name_spl) : `${item.class_section[0].toUpperCase()}${item.class_section[1].toUpperCase()}${item.class_section[2].toUpperCase()}` ;
        const data = {class_section:id, school:schoolState.id, user:user.id, name:item.class_section, short_name:short_name};
        appServices.insertClassSectionSchool_(schoolState.title, data).then(res => {
            setLoading(false);
            setAllClassSectionSchool(addItemToArray([...allClassSectionSchool, {...res.data, is_save:false}]))
        }).catch(e => {
            setLoading(false);
            setError({title:'Class Section Insert Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
        })
    }
    const onClickDeleteClassSectionSchool = (id) => {
        setLoading(true);
        setError(initialErrorState);
        appServices.deleteClassSectionSchool_(schoolState.title, id).then(res => {
            setLoading(false);
            fetchAllClassSectionSchool(schoolState.id);
        }).catch(e => {
            setLoading(false);
            setError({title:'Class Section Delete Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
        })
    }
    const onDeleteClickClass = (id) => {
        setLoading(true);
        setError(initialErrorState);
        appServices.deleteClassSchool_(schoolState.title, id).then(res => {
            setLoading(false);
            fetchAllClassesSchool(schoolState.sname, schoolState.id);
        }).catch(e => {
            setLoading(false);
            setError({title:'Class Delete Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
        })
    }
    const onClickCheckClass = (num, item={}) => {
        if(item.name){
            setLoading(true);
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            const data={name:item.name, user:user.id, class_section_school:item.class_section_school, classes:item.classes, school:schoolState.id}
            appServices.insertClassSchool_(schoolState.title, data).then(res => {
                setAllClasses(addItemToArray([...allClasses, res.data]));
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setError({title:'Class Insert Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
            })
        }
    }
    const onSetupClass = (i, item={}) => {
        const all_class = allClasses.filter((it) => it.class_section_school === item.id);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const j = i + 1;
        if(j === allClassSectionSchool.length && totClasses === all_class.length){
            setLoading(true);
            appServices.updateRegisteredSchool(schoolState.title, schoolState.id, {is_setup_class:true}).then(res => {
                setTimeout(() => {
                    setLoading(false);
                    setTotClasses(0);
                    setClassArr([]);
                    setClassIndex(0);
                    setSchoolState(res.data);
                    setNextify(true);
                    setNextifyPos(getNextifyPosition(res.data));
                    window.location.reload();
                }, 1000);
            }).catch(e => {
                setLoading(false);
                setError({title:'Data Finish Error', data:[e.response.data.detail ? e.response.data.detail : "Something Went Wrong"]});
            })
            return;
        }
        if(totClasses === all_class.length){
            setLoading(true);
            setTimeout(() => {
                setClassIndex(i+1);
                setLoading(false);
                //fetchAllClassesSchool(schoolState.title, schoolState.id);
                setTotClasses(0);
                setIsNextifyClass(false);
                setSuccess({title:'Success', text:`All Classes For ${item.name} Has Been Saved Successfully`})
            }, 200);
            return;
        }
        setTimeout(() => {
            setError({title:'Class Insert Error', data:[all_class.length > 0 ? `Error... Only ${all_class.length} Class(es) Have Been Added` : `Error... No Class Have Been Added`]});
        })
        return;
    }
    const updateDataMyself = (num=0, arr=[]) => {
        let data = [];
        for(let i = 0; i < arr.length; i++){
            if(i < num){
                data.push({...arr[i], done:true});
                continue;
            }
            data.push({...arr[i], done:false});
        }
        return data;
    }
    // useEffect(() => {
    //     if(searchParams.get('r') === "branch" && )
    //     setStep(searchParams.get('r') ? parseInt(searchParams.get('r')) : 0);
    // }, [searchParams])

    useEffect(() => {
        if(reload && user.id && searchParams.get('type') === "success"){
            setReload(false);
            fetchAllState('122');
            fetchCart(user.id, true, searchParams.get('is_trial') ? true : false);
            fetchAllClasses();
            return;
        }
        if(reload && user.id){
            setReload(false);
            fetchAllState('122');
            fetchCart(user.id, false, searchParams.get('is_trial') ? true : false);
            fetchAllClasses();
            return;
        }
        if(reload){
            fetchCart(user.id, false, searchParams.get('is_trial') ? true : false);
        }
    }, [reload, user, searchParams, fetchCart])
    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submit === "register_school"){
            setSubmit('registered_school');
            return;
        }
        if(Object.keys(formUserBranchError).length === 0 && submit === "insert_school_branch"){
            setSubmit('inserted_school_branch');
            return;
        }
        if(Object.keys(formUserSessionError).length === 0 && submit === "insert_school_session"){
            setSubmit('inserted_school_session');
            return;
        }
        // if(Object.keys(formClassSectionError).length === 0 && submit === "insert_class_section"){
        //     setSubmit('inserted_class_section');
        //     return;
        // }   
    }, [formUserError, formUserBranchError, formUserSessionError, submit]);
    useEffect(() => {
        if(isNextifyClass && totClasses > 0){
            setClassArr(addArrayFromNum(totClasses, allClassSectionSchool[classIndex].short_name))
        }
    }, [isNextifyClass, totClasses, classIndex, allClassSectionSchool])
    useEffect(() => {
        if(schoolState.id  && nextify){
            setStep(nextifyPos);
            setVoData((prev) => prev.length > 0 ? updateDataMyself(getNextifyPosition(schoolState), prev) : [])
            setNextify(false);
            fetchAllSchoolBranch(schoolState.title, schoolState.id);
            fetchAllClassSection(schoolState.id);
            fetchAllClassSectionSchool(schoolState.id);
            fetchAllClassesSchool(schoolState.title, schoolState.id);
            fetchSession(schoolState.id);
            fetchAllTerm(schoolState.id);
        }
    }, [schoolState, nextify, nextifyPos]);
    
    

    useEffect(() => {
        if(navigate){
            setNavigate('');
            return nav(navigate);
        }
    }, [navigate, nav])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const stateOption = st.map((it, i) => {
        return(
            <option value={it.id} key={i}>{it.name}</option>
        )
    })
    const handleBranchChange = (e) => {
        setFormUserBranch({...formUserBranch, [e.target.name]:e.target.value});
    }
    const handleSessionChange = (e) => {
        setFormUserSession({...formUserSession, [e.target.name]:e.target.value});
    }
    const handleClassIndexChange = (val) => {
        setTotClasses(parseInt(val));
    }
    const onChangeClassSectionForm = (e, num=-1, cnum=-1) => {
        setAllClassSectionSchool(updateArray(allClassSectionSchool, num, {...allClassSectionSchool[num], is_save:true, [e.target.name]:e.target.value}))
    }
    const handleUserChange = (e) => {
        if(e.target.name === "country" && e.target.value === "122"){
            fetchAllState(e.target.value);
            setFormUser({...formUser, [e.target.name]:e.target.value, phone_code:'234', phone:''});
            return
        }
        if(e.target.name === "country" && !e.target.value){
            setSt([]);
            setFormUser({...formUser, [e.target.name]:e.target.value, phone_code:'', phone:''});
            return
        }
        if(e.target.name === "use_email_exist"){
            if(toBoolean(e.target.value) === true){
                setFormUser({...formUser, [e.target.name]:!toBoolean(e.target.value), email:''});
                return;
            }
            setFormUser({...formUser, [e.target.name]:!toBoolean(e.target.value), email:user.email});
            return
        }
        if(e.target.name === "use_phone_exist"){
            if(toBoolean(e.target.value) === true){
                setFormUser({...formUser, [e.target.name]:!toBoolean(e.target.value), phone:''});
                return;
            }
            setFormUser({...formUser, [e.target.name]:!toBoolean(e.target.value), phone:user.phone_number});
            return
        }
        setFormUser({...formUser, [e.target.name]:e.target.value});
    }
    const handleUserChangeFile = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value});
    }
    const classIndexItem = allClassSectionSchool.length > 0 ? allClassSectionSchool[classIndex] : {id:0, short_name:''};
    const handleClassChange = (e, num) => {
         setClassArr(updateArray(classArr, num, {...classArr[num], [e.target.name]:e.target.value}))
    }
    const insertTermOptions = allTerm.map((item, i) => {
        return(
            <option value={item.id}>{item.term}</option>
        )
    })
    console.log(state)
    const access = localStorage.getItem('access') ? JSON.parse(localStorage.getItem('access')) : {access:'', refresh:''}
    return(
        <>
        {preloadingLine && <PreloaderLine />}
        <NotificationWrapper>
            {error.title && (errorHandler)}
            {warning.text && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
        </NotificationWrapper> 
        {!preloading && (
            <>
           
        {!schoolState.is_setup_app ? (
            <>
            <HeadComponent title="Voskool - Setup Your School Account" />
            {loading && <Loader />}
                {!step &&  (
                <VoWrapper page="Register" action="Register School" 
                    page_under={searchParams.get('is_trial') ? "Your school trial period has been activated successfully" : "Your payment was successful, register your school with us to access our amazing features"}
                    back={''} data={voData}>
                    <Wrapper>
                        {state.is_trial && (
                            <>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-slightly-small app-text-color'>You are currently using the <b style={{color:'red'}}>TRIAL</b> mode</span>
                                </div>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-very-small app-text-color'>Generated Free Trial School Domain Server: <b style={{color:'green'}}>#{state.domain}</b></span>
                                </div>
                            </>
                        )}
                        <form onSubmit={e => onRegisterSchool(e)}>
                            <Content>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter School Short Name /<br /> (School Name Abbrev) / (School Domain Name)<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.title ? `f-c b-red`: `f-c`} name="title" placeholder="School Short / Domain Name" value={formUser.title} onChange={e => handleUserChange(e)} />
                                    {formUserError.title && (<span className="error">{formUserError.title}</span>)}
                                    {state.is_trial && (
                                        <Note>This domain will not be accessible until after upgrade, you can click <Link to="/">here</Link> to choose a better plan now and avoid any future hassle</Note>
                                    )}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter School Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.name ? `f-c b-red`: `f-c`} name="name" placeholder="School Name" value={formUser.name} onChange={e => handleUserChange(e)} />
                                    {formUserError.name && (<span className="error">{formUserError.name}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Choose School icon</label>
                                    <input type="file" className={formUserError.icon ? `f-c b-red`: `f-c`} name="icon" value={formUser.icon_value} onChange={e => handleUserChangeFile(e)} />
                                    {formUserError.icon && (<span className="error">{formUserError.icon}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Enter School Email Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    {!formUser.use_email_exist && (
                                        <>
                                        <input type="email" className={formUserError.email ? `f-c b-red`: `f-c`} name="email" disabled={formUser.use_email_exist} placeholder="School Email Address" value={formUser.email} onChange={e => handleUserChange(e)} />
                                        {formUserError.email && (<span className="error">{formUserError.email}</span>)}
                                        </>
                                    )}
                                    <div>
                                        <span className='font-super-small'><input type={"checkbox"} disabled name="use_email_exist" checked={formUser.use_email_exist} value={formUser.use_email_exist} onChange={e => handleUserChange(e)} /> Use Your Email Address?</span>
                                    </div>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select School Country<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.country ? `f-c b-red`: `f-c`} name="country" value={formUser.country} onChange={e => handleUserChange(e)}>
                                        <option value="">Select Country</option>
                                        <option value="122">Nigeria</option>
                                    </select>
                                    {formUserError.country && (<span className="error">{formUserError.country}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Enter School Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    {!formUser.use_phone_exist && (
                                        <div style={{display:'flex', flexWrap:'wrap', width:'100%', justifyContent:'stretch'}}>
                                            <div style={{padding:'3px'}}>
                                                <span className='font-small app-text-color'>+</span>
                                            </div>
                                            <div style={{padding:'3px'}}>
                                                <div>
                                                    <input className="inp-num" style={{width:'40px'}} name="phone_code" value={formUser.phone_code} type="text" readOnly />
                                                </div>
                                            </div>
                                            <div style={{padding:'3px'}}>
                                                <input type="number" className={formUserError.phone ? `f-c b-red`: `f-c`} name="phone" disabled={formUser.use_phone_exist} placeholder="School Phone Number" value={formUser.phone} onChange={e => handleUserChange(e)} />
                                                {formUserError.phone && (<span className="error">{formUserError.phone}</span>)}
                                            </div>
                                    </div>
                                    )}
                                    
                                    
                                    <div>
                                        <span className='font-super-small'><input type={"checkbox"} name="use_phone_exist" checked={formUser.use_phone_exist} value={formUser.use_phone_exist} onChange={e => handleUserChange(e)} /> Use Your Phone Number?</span>
                                    </div>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>What time does your school open for business?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="time" className={formUserError.working_time_start ? `f-c b-red`: `f-c`} name="working_time_start" placeholder="School Working Time Start" value={formUser.working_time_start} onChange={e => handleUserChange(e)} />
                                    {formUserError.working_time_start && (<span className="error">{formUserError.working_time_start}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>What time does your school closes?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="time" className={formUserError.name ? `f-c b-red`: `f-c`} name="working_time_end" placeholder="School Working Time End" value={formUser.working_time_end} onChange={e => handleUserChange(e)} />
                                    {formUserError.working_time_end && (<span className="error">{formUserError.working_time_end}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-100">
                                    <div><label className='disp-block'>Enter School Motto<span style={{color:'red', fontSize:'1rem'}}>*</span></label></div>
                                    <textarea type="text" className={formUserError.motto ? `f-c b-red`: `f-c`} name="motto" placeholder="School Motto..." value={formUser.motto} onChange={e => handleUserChange(e)} />
                                    {formUserError.motto && (<span className="error">{formUserError.motto}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100">
                                    <div style={{display:'flex', justifyContent:'stretch'}}>
                                        <div>
                                            <label>Select School Primary Color<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                            <select className={formUserError.pri_color ? `f-c b-red`: `f-c`} name="pri_color" value={formUser.pri_color} onChange={e => handleUserChange(e)}>
                                                <option value="">Select Primary Color</option>
                                                <option value="blue">Blue</option>
                                                <option value="red">Red</option>
                                                <option value="green">Green</option>
                                            </select>
                                        </div>
                                        {formUser.pri_color && (
                                            <div style={{marginTop:'20px', padding:'10px', background:formUser.pri_color, width:'50%', height:'20px', borderRadius:'5px'}}>
                                            </div>
                                        )}
                                    </div>
                                    {formUserError.pri_color && (<span className="error">{formUserError.pri_color}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                </motion.div>
                            </Content>
                        </form>
                    </Wrapper>
                </VoWrapper>
            )}
            {step === 1 && (
                <VoWrapper page="Create Branch" action="Create Location(s)" 
                page_under="Create your school branch(es)/location(s)" 
                back={''} data={voData}>
                    <Wrapper>
                        {state.is_trial && (
                            <>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-slightly-small app-text-color'>You are currently using the <b style={{color:'red'}}>TRIAL</b> mode</span>
                                </div>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-very-small app-text-color'>Generated Free Trial School Domain Server: <b style={{color:'green'}}>#{state.domain}</b></span>
                                </div>
                            </>
                        )}
                        <Content>
                            <div className='f-100 align-center' style={{marginBottom:'20px'}}>
                                <span className='font-big fw-700 app-text-color text-capitalize'>{schoolState.name}</span>
                            </div>
                        </Content>
                        <form onSubmit={e => onRegisterSchoolBranch(e)}>
                            <Content>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Branch Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserBranchError.name ? `f-c b-red`: `f-c`} name="name" placeholder="Enter Branch/Location Name" value={formUserBranch.name} onChange={e => handleBranchChange(e)} />
                                    {formUserBranchError.name && (<span className="error">{formUserBranchError.name}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Branch State<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserBranchError.state ? `f-c b-red`: `f-c`} name="state" value={formUserBranch.state} onChange={e => handleBranchChange(e)}>
                                        <option value="">Select State {st.length > 0 && '...'}</option>
                                        {stateOption}
                                    </select>
                                    {formUserBranchError.state && (<span className="error">{formUserBranchError.state}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Branch City Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserBranchError.city ? `f-c b-red`: `f-c`} name="city" placeholder="City Name" value={formUserBranch.city} onChange={e => handleBranchChange(e)} />
                                    {formUserBranchError.city && (<span className="error">{formUserBranchError.city}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Branch Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea type="text" className={formUserBranchError.address ? `f-c b-red`: `f-c`} name="address" placeholder="School Address..." value={formUserBranch.address} onChange={e => handleBranchChange(e)} />
                                    {formUserBranchError.address && (<span className="error">{formUserBranchError.address}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                    <button type="submit" className="mybtn" style={{background:'blue', color:'#ccc'}}><i className="fas fa-plus"></i> Create New Branch</button>
                                </motion.div>
                            </Content>
                        </form>
                    </Wrapper>
                    <Wrapper>
                        <Content>
                            <div className='f-100 align-center'>
                                <span className='font-med fw-700 app-text-color'>All School Location(s)/Branch(es)</span>
                            </div>
                            <div className='f-100'>
                                <TABLE style={{width:'100%'}}>
                                    <thead>
                                        <TR>
                                            <TH align="left" style={{width:'20%', textAlign:'left'}}>S/N</TH>
                                            <TH align="left" style={{width:'20%', textAlign:'left'}}>Name</TH>
                                            <TH align="left" style={{width:'20%', textAlign:'left'}}>State</TH>
                                            <TH align="left"  style={{width:'20%', textAlign:'left'}}>City</TH>
                                            <TH align="left" style={{width:'20%', textAlign:'left'}}>Address</TH>
                                            <TH align="left" style={{width:'20%'}}>Act</TH>
                                        </TR>
                                    </thead>
                                    <tbody>
                                        {allSchoolBranch.length > 0 ? allSchoolBranch.map((item, i) => {
                                            const j = i +1;
                                            return(
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD>{j}</TD>
                                                        <TD>{item.name}</TD>
                                                        <TD>{item.state_name}</TD>
                                                        <TD>{item.city}</TD>
                                                        <TD>{item.location.length > 20 ? `${item.location.slice(0, 20)}...` : item.location}</TD>
                                                        <TD><button className='btn hover-opacity font-very-small' onClick={() => onDeleteBranch(item.id)} style={{color:'#ccc', padding:'10px', background:'#f12', borderRadius:'10px'}}><i className='fas fa-trash'></i></button></TD>
                                                    </TR>
                                                </Fragment>
                                            )
                                        }) : (
                                            <TR><TD align='center' colspan={5}><span className='red font-small'>No Record Found</span></TD></TR>
                                        )}
                                    </tbody>
                                </TABLE>
                            </div>
                            {allSchoolBranch.length > 0 && (
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                    <button type="button" onClick={() => onSetupBranch()} className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                </motion.div>
                            )}
                            
                        </Content>
                    </Wrapper>
                </VoWrapper>
            )}
            {step === 2 && (
                <VoWrapper page="Create Session" action="Create Current Session/Term" 
                page_under="Create school current session/term" 
                back={''} data={voData}>
                    <Wrapper>
                        {state.is_trial && (
                            <>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-slightly-small app-text-color'>You are currently using the <b style={{color:'red'}}>TRIAL</b> mode</span>
                                </div>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-very-small app-text-color'>Generated Free Trial School Domain Server: <b style={{color:'green'}}>#{state.domain}</b></span>
                                </div>
                            </>
                        )}
                        <Content>
                            <div className='f-100 align-center' style={{marginBottom:'20px'}}>
                                <span className='font-big fw-700 app-text-color text-capitalize'>{schoolState.name}</span>
                            </div>
                        </Content>
                        <form onSubmit={e => onRegisterSchoolSession(e)}>
                            <Content>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Current Session<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserSessionError.session ? `f-c b-red`: `f-c`} name="session" placeholder="Enter Session e.g 2022/2023" value={formUserSession.session} onChange={e => handleSessionChange(e)} />
                                    {formUserSessionError.session && (<span className="error">{formUserSessionError.session}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Current Year<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="year" className={formUserSessionError.year ? `f-c b-red`: `f-c`} name="year" placeholder="Enter Year e.g 2023" value={formUserSession.year} onChange={e => handleSessionChange(e)} />
                                    {formUserSessionError.year && (<span className="error">{formUserSessionError.year}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Current Term<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserSessionError.term ? `f-c b-red text-capitalize`: `f-c text-capitalize`} name="term" placeholder="Select Term" value={formUserSession.term} onChange={e => handleSessionChange(e)}>
                                        <option value="">Select Term</option>
                                        {insertTermOptions}
                                    </select>
                                    {formUserSessionError.term && (<span className="error">{formUserSessionError.term}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter School Resumption Date For This Term<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="date" className={formUserSessionError.res_date ? `f-c b-red`: `f-c`} name="res_date" placeholder="Resumption Date..." value={formUserSession.res_date} onChange={e => handleSessionChange(e)} />
                                    {formUserSessionError.res_date && (<span className="error">{formUserSessionError.res_date}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter School Enrollment Start Date (optional) </label>
                                    <input type="date" className={formUserSessionError.interview_start_date ? `f-c b-red`: `f-c`} name="interview_start_date" placeholder="Start Date..." value={formUserSession.interview_start_date} onChange={e => handleSessionChange(e)} />
                                    {formUserSessionError.interview_start_date && (<span className="error">{formUserSessionError.interview_start_date}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter School Enrollment End Date (optional)</label>
                                    <input type="date" className={formUserSessionError.interview_end_date ? `f-c b-red`: `f-c`} name="interview_start_date" placeholder="End Date..." value={formUserSession.interview_end_date} onChange={e => handleSessionChange(e)} />
                                    {formUserSessionError.interview_end_date && (<span className="error">{formUserSessionError.interview_end_date}</span>)}
                                </motion.div>
                                
                            </Content>
                            <div className='f-100' style={{display:'flex', justifyContent:'space-between'}}>
                                <div className='align-center form-group '>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="align-center f-100" style={{marginTop:'15px'}}>
                                        <button type="button" onClick={() => onBackToBranch()} className="btn bg-none br-5 rem1" style={{color:'#34f'}}><i className="fas fa-arrow-left"></i> Back </button>
                                    </motion.div>
                                </div>
                                <div className='align-center'>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                        <button type="submit" className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                    </motion.div>
                                </div>
                            </div>
                        </form>
                        
                    </Wrapper>
                </VoWrapper>
            )}
            {step === 3 && (
                <VoWrapper page="Create Class Sections" action="Create Class Sections" 
                page_under="Create your school class sections" 
                back={''} data={voData}>
                    <Wrapper>
                        {state.is_trial && (
                            <>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-slightly-small app-text-color'>You are currently using the <b style={{color:'red'}}>TRIAL</b> mode</span>
                                </div>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-very-small app-text-color'>Generated Free Trial School Domain Server: <b style={{color:'green'}}>#{state.domain}</b></span>
                                </div>
                            </>
                        )}
                        <Content>
                            <div className='f-100 align-center' style={{marginBottom:'20px'}}>
                                <span className='font-big fw-700 app-text-color text-capitalize'>{schoolState.name}</span>
                            </div>
                        </Content>
                    </Wrapper>
                    <Wrapper>
                        <Content>
                            {//<Note>To add class click on <i className='fas fa-check'></i>, to remove click on <i className='fas fa-times'></i></Note>
                            }
                            <div className='f-100 align-center'>
                                <span className='font-med fw-700 app-text-color'>Manage Custom Class Sections</span>
                            </div>
                            <div className='f-100'>
                                <TABLE style={{width:'100%'}}>
                                    <thead>
                                        <TR>
                                            <TH align="left" style={{textAlign:'left'}}>S/N</TH>
                                            <TH align="left" style={{width:'90%', textAlign:'left'}}>Class Section</TH>
                                            <TH align="left">Act</TH>
                                        </TR>
                                    </thead>
                                    <tbody>
                                        {allClassSection.map((item, i) => {
                                            let j = i + 1;
                                            let arr = allClassSectionSchool.filter((it) => it.class_section === item.id);
                                            return(
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD>{j}</TD>
                                                        <TD>
                                                            {arr.length > 0 ? (
                                                                <>
                                                                    <div className='wrap' style={{display:'flex', justifyContent:'stretch'}}>
                                                                        <div style={{padding:'2px'}}>
                                                                            <label className='font-super-small fw-600'>Class Section Name</label>
                                                                            <input type="text" name="edit_name" placeholder='Enter Class Section Name' className='form-control font-very-small' onChange={e => onChangeClassSectionForm(e, arr[0].num, item.num)} style={{padding:'3px', background:'#fff'}} value={arr[0].edit_name ?arr[0].edit_name: arr[0].name } />
                                                                            {arr[0].num === formClassSectionError.num && (<>{formClassSectionError.name && (<><div className='disp-block align-center'><span className='red app-text-color font-very-small'>{formClassSectionError.name}</span></div></>)}</>)}
                                                                        </div>
                                                                        <div  style={{padding:'2px'}}>
                                                                            <label className='font-super-small fw-600'>Class Section Short Name</label>
                                                                            <input type="text" name="edit_short_name" placeholder='Enter Class Section Short Name' className='form-control font-very-small' onChange={e => onChangeClassSectionForm(e, arr[0].num, item.num)} style={{padding:'3px', background:'#fff'}} value={arr[0].edit_short_name ? arr[0].edit_short_name: arr[0].short_name } />
                                                                            {arr[0].num === formClassSectionError.num && (<>{formClassSectionError.short_name && (<><div className='disp-block'><span className='red font-super-small fw-600'>{formClassSectionError.short_name}</span></div></>)}</>)}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <span className='font-slightly-small text-capitalize'>{item.class_section} {item.other_name && `/ ${item.other_name}`}</span>
                                                            )}
                                                        </TD>
                                                        <TD>{arr.length > 0 ? (<>{arr[0].is_save && (<button type="button" className='btn font-very-small hover-opacity disp-block' onClick={e => onSaveClassSection(e, arr[0].num, arr[0], item.num)} style={{background:'#34f', color:'#ddd', padding:'5px', borderRadius:'5px', marginBottom:'3px'}}>Save</button>)}<button type="button" className='btn hover-opacity font-very-small' onClick={() => onClickDeleteClassSectionSchool(arr[0].id)} style={{color:'#ccc', padding:'10px', background:'#f12', borderRadius:'10px'}}><i className='fas fa-trash'></i></button></>) : 
                                                            (<button onClick={() => onClickCheck(item.id, item)} className='btn font-small' style={{background:'#3f1', color:'#ddd', padding:'5px', borderRadius:'5px'}}> <i className='fas fa-check'></i></button>)}</TD>
                                                    </TR>
                                                </Fragment>
                                            )
                                        })}
                                    </tbody>
                                </TABLE>
                                {allClassSectionSchool.length > 0 && (
                                    <div className='f-100' style={{display:'flex', justifyContent:'space-between'}}>
                                        <div className='align-center form-group '>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="align-center f-100" style={{marginTop:'15px'}}>
                                                <button type="button" onClick={() => onToSession()} className="btn bg-none br-5 rem1" style={{color:'#34f'}}><i className="fas fa-arrow-left"></i> Back </button>
                                            </motion.div>
                                        </div>
                                        <div className='align-center'>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                                <button type="button" onClick={() => onSetupClassSection()} className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                            </motion.div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Content>
                    </Wrapper>
                </VoWrapper>
            )}
            {step === 4 && (
                <VoWrapper page="Create Classes" action="Create Classes" 
                page_under="Create your school classes" 
                back={''} data={voData}>
                    <Wrapper>
                        {state.is_trial && (
                            <>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-slightly-small app-text-color'>You are currently using the <b style={{color:'red'}}>TRIAL</b> mode</span>
                                </div>
                                <div className='disp-block align-center padd-4px' style={{marginBottom:'10px'}}>
                                    <span className='font-very-small app-text-color'>Generated Free Trial School Domain Server: <b style={{color:'green'}}>#{state.domain}</b></span>
                                </div>
                            </>
                        )}
                        <Content>
                            <div className='f-100 align-center' style={{marginBottom:'10px'}}>
                                <span className='font-big fw-700 app-text-color text-capitalize'>{schoolState.name}</span>
                            </div>
                            <div className='f-100 align-center' style={{marginBottom:'20px'}}>
                                <span className='font-big fw-700 app-text-color text-capitalize'>{classIndexItem.name} Section</span>
                            </div>

                            
                            {!isNextifyClass ? (
                                <>
                                    <div className='f-100'>
                                        <label className='disp-block fw-600 app-text-color font-very-small text-capitalize'>Enter Total Number Of Classes In {classIndexItem.name}</label>
                                        <input type="number" className='form-control app-text-color font-small' placeholder='Enter Total Number Of Classes' name="class_index" onChange={e => handleClassIndexChange(e.target.value)} value={totClasses>0 ? totClasses : ''} />
                                    </div>
                                    <div className='f-100' style={{display:'flex', justifyContent:'space-between'}}>
                                        <div className='align-center form-group '>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="align-center f-100" style={{marginTop:'15px'}}>
                                                <button type="button" onClick={() => onBackToClassSection(classIndex)} className="btn bg-none br-5 rem1" style={{color:'#34f'}}><i className="fas fa-arrow-left"></i> Back </button>
                                            </motion.div>
                                        </div>
                                        <div className='align-center'>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                                <button type="button" onClick={() => onStartCreateClass(classIndexItem.class_section, classIndexItem.short_name)} className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                            </motion.div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='f-100'>
                                        <TABLE>
                                            <thead>
                                                <TR>
                                                    <TH style={{textAlign:'left'}}>S/N</TH>
                                                    <TH align="left" style={{width:'90%', textAlign:'left'}}>Class Name</TH>
                                                    <TH>Act</TH>
                                                </TR>
                                            </thead>
                                            <tbody>
                                                {classArr.map((item, i) => {
                                                    const j = i +1;
                                                    const classIndexItem = allClassSectionSchool[classIndex];
                                                    const orig_class = allOrigClasses.filter((it) => it.class_section === classIndexItem.class_section);
                                                    const orig_class_id = orig_class[item.num].id ? orig_class[item.num].id : -1;
                                                    const cls = allClasses.filter((it) => it.classes === orig_class_id);
                                                    return(
                                                        <Fragment key={i}>
                                                            <TR>
                                                                <TD>{j}</TD>
                                                                <TD>
                                                                    {cls.length > 0 ? (
                                                                        <span className="font-small app-text-color fw-600">{cls[0].name}</span>
                                                                    ) : (
                                                                        <>
                                                                            <label className='disp-block fw-600 app-text-color font-very-small text-capitalize'>Enter Class Name</label>
                                                                            <input type="text" name="name" className='form-control font-slightly-small' onChange={e => handleClassChange(e, item.num)} style={{background:'#fff'}} value={item.name} />
                                                                        </>
                                                                    )}
                                                                </TD>
                                                                <TD>{cls.length < 1 ? <button onClick={() => onClickCheckClass(item.num, {...item, class_section_school:classIndexItem.id, classes:orig_class[item.num].id})} className='btn font-small' style={{background:'#3f1', color:'#ddd', padding:'5px', borderRadius:'5px'}}> <i className='fas fa-check'></i></button> : <button type="button" className='btn hover-opacity font-very-small' onClick={() => onDeleteClickClass(cls[0].id)} style={{color:'#ccc', padding:'10px', background:'#f12', borderRadius:'10px'}}><i className='fas fa-trash'></i></button>}</TD>
                                                            </TR>
                                                        </Fragment>
                                                    )
                                                })}
                                            </tbody>
                                        </TABLE>
                                        <div className='f-100' style={{display:'flex', justifyContent:'space-between'}}>
                                            <div className='align-center form-group '>
                                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="align-center f-100" style={{marginTop:'15px'}}>
                                                    <button type="button" onClick={() => {setIsNextifyClass(false);}} className="btn bg-none br-5 rem1" style={{color:'#34f'}}><i className="fas fa-arrow-left"></i> Back </button>
                                                </motion.div>
                                            </div>
                                            <div className='align-center'>
                                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                                    {(classIndex + 1) === allClassSectionSchool.length ? <button type="button" onClick={() => onSetupClass(classIndex, classIndexItem)} className="mybtn br-5 shadow rem1 bg-black">Finish <i className="fas fa-paper-plane"></i></button> : <button type="button" onClick={() => onSetupClass(classIndex, classIndexItem)} className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>}
                                                </motion.div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            
                        </Content>
                    </Wrapper>
                </VoWrapper>
            )}
            </>
        ) : (
            <>
                <HeadComponent title="Voskool - App Setup Verified Successfully" />
                <VoWrapper page="School App Verfied" action={schoolState.name}
                page_under="Your School App Has Been Verified And Is Ready To Use" 
                back={''} data={voData}>
                    <Wrapper>
                        <Content>
                            <div className='f-100' style={{marginBottom:'10px'}}>
                                <span><span className='font-med fw-600 green text-capitalize'>Congratulations, Your School App Has Been Verified</span> </span>
                                <div>
                                    <br />
                                    <span className='font-med fw-600 app-text-color'>Your school app account has been verified successfully. </span>
                                </div>
                            </div>
                            <div className='f-100' style={{marginBottom:'10px'}}>
                                <div className='disp-block align-center' style={{padding:'10px'}}>
                                    <span className='font-big fw-700' style={{color:'#000'}}>Tour {schoolState.title.toUpperCase()} App is ready, start exploring our amazing features </span>
                                </div>
                                <div className='disp-block align-center'>
                                    <Link target={'_blank'} to={`https://app.voskool.com/${schoolState.title}?act=${access.access}rft=${access.refresh}`} className='mybtn' style={{background:'cornflowerblue', color:'#ddd'}}>Begin App</Link>
                                </div>
                            </div>
                            <div className='f-100 align-right' style={{marginBottom:'10px'}}>
                                <span className='font-med fw-600 app-text-color'><br /> Thank you for your patronage</span>
                            </div>
                            <div className='f-100 align-center' style={{marginTop:'10px'}}>
                                <Link to="/"><i className='fas fa-arrow-left'></i> Back</Link>
                            </div>
                        </Content>
                    </Wrapper>
                </VoWrapper>
            </>
        )}
         
         </>
        )}
        </>
        
    )
}

export default CreateSchoolAccount;