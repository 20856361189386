import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import CenteredPage from "components/app/centeredPage";
import Background from "components/Background";

import Loader from "components/app/loader";

import { HeadComponent } from "components/head";
import { reset_password } from "actions/auth";

import voskool_logo from "images/voskool_logo.png";

const PasswordResetPage = () => {
    const [state, setState] = useState({password:'', re_password:''});
    const [loading, setLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState('');

    const {uid, token} = useParams();

    const onChange = (e) => {
        setState({...state, [e.target.name]:e.target.value});
    }
    const onSubmit = e => {
        e.preventDefault();
        if(state.password === state.re_password){
            setLoading(true);
            reset_password(uid, token, state.password, state.re_password).then(res => {
                setLoading(false);
                setIsSent(true);
            }).catch(e => {
                setLoading(false);
                setIsSent(false);
                setError('Something Went Wrong');
            })
            return;
        }
        setError('Password and re-enter password do not match');
        return;
    }
    return(
        <>
            <HeadComponent title="Password Reset Page" />
            {loading && <Loader />}
            <Background bg_color="#26223a">
            <CenteredPage type="small" bg_style={{background:'#eed'}}>
                <div className="disp-block align-center">
                    <img style={{width:'150px', height:'50px'}} src={voskool_logo} alt="Voskool" />
                </div>
                {isSent ? (
                    <>
                    <div className="align-center">
                        <h3 style={{color:'green'}}>Your password has been changed successfully</h3>
                        <Link to="/login"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    </>
                ) : (
                    <>
                        <div className="align-center">
                            <h2>Reset Password</h2>
                        </div>
                        <div className="disp-block" style={{marginTop:'15px'}}>
                            <form onSubmit={e => onSubmit(e)}>
                                <div className="disp-block" style={{marginTop:'15px'}}>
                                    <label className="align-left fw-500 font-very-small">Password</label>
                                    <input type="password" name="password" onChange={e => onChange(e)} required placeholder="Enter New Password" className="form-control font-small" value={state.password} />
                                </div>
                                <div className="disp-block" style={{marginTop:'15px'}}>
                                    <label className="align-left fw-500 font-very-small">Re-Enter Password</label>
                                    <input type="password" name="re_password" onChange={e => onChange(e)} required placeholder="Re-Enter New Password" className="form-control font-small" value={state.re_password} />
                                </div>
                                {error && (
                                    <div className="align-center">
                                        <h5 style={{color:'red'}}>{error}</h5>
                                    </div>
                                )}
                                <div className="disp-block align-center" style={{marginTop:'15px'}}>
                                    <button type="submit" className="mybtn font-small hover" style={{background:'#45f', color:'#eee'}}>Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </CenteredPage>
            </Background>
        </>
    )
}

export default PasswordResetPage;