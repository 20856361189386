import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 30;
    top: 0; right: 0;
  bottom: 0; left: 0; 
  border-radius: 5px;
  background: rgba(0,0,0,0.5);
`;

export const Content = styled.div`
    height: 15px;
    width: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 10%;
    margin: auto;
    .loader--dot {
        animation-name: loader;
        animation-timing-function: ease-in-out;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        border: 2px solid white;
    }
    .loader--dot:first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
    }
    .loader--dot:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
    }
    .loader--dot:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
    }
    .loader--dot:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
    }
    .loader--dot:nth-child(5) {
    background-color: #fbef5a;
    animation-delay: 0.1s;
    }
    .loader--dot:nth-child(6) {
    background-color: blue;
    animation-delay: 0s;
    }
    .loader--text {
    position: absolute;
    top: 200%;
    left: 20%;
    right: 0;
    width: 7rem;
    margin: auto;
    }
    .loader--text:after {
    content: "Almost There!";
    font-weight: 700;
    font-size: .9rem;
    color: blue;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    @media(max-width: 540px){
        font-size: .6rem;
    }
    }
    @keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
`;