import React from "react";
import { Wrapper } from "./style";
import ComponentHeader from "components/compoHeader";
import PlansAndPricingComponents from "components/PlansAndPricing";

const PlansAndPricing = () => {
    return(
        <>
            <Wrapper id="plans" >
                <ComponentHeader title="Plans & Pricing" styleWrapper={{margin:'0'}} numIcons={12} />
                <PlansAndPricingComponents />
            </Wrapper>    
        </>    
    );
}

export default PlansAndPricing;