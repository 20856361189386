import styled from "styled-components";

export const Wrapper = styled.div`
  transform: translateY(-15px);
  padding: 0px 17px;
  width: 100%;

  .yellow{
      color:#fff811 !important;
  }
  .green{
      color:#44ff11 !important;
  }
  .red{
      color:#ff2211 !important;
  }
  @media (max-width:500px){
    transform: translateY(-10px);
  }

`;
export const Content = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 30px;

  @media (max-width:500px){
    padding: 10px;
  }
`;

export const InputContent = styled.div`
    display: flex;
    width: 100%;
    margin: 15px 0px;
    justify-content: center;
    .input-cont{
        position: relative;
        width: 50%;
        input{
            width: 100%;
            padding: 5px 10px;
            font-size: .9rem;
            color:var(--unAssignedColor);
            border: 2px solid var(--unAssignedColor);
            border-radius: 7px;
            background-color: #eee;
            :focus{
                outline: none;
            }
            @media (max-width:540px){
                font-size: .7rem;
            }
            @media (max-width:280px){
                font-size: .5rem;
            }
        }
        .input-icon{
            position: absolute;
            top: 20%;
            right: 5%;

            i{
                color: #555;
            }
        }
    }
`;

export const SelectContent = styled.div`
    display: grid;
    grid-template-columns: .3fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width:1280px){
        grid-template-columns: .5fr 1fr 1fr;
    }
    @media (max-width:540px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }
    @media (max-width:280px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    

    select, input{
        padding: 5px;
        font-size: .9rem;
        color:var(--unAssignedColor);
        border: 2px solid var(--unAssignedColor);
        border-radius: 7px;
        @media (max-width:540px){
            font-size: .7rem;
            width: 100%;
        }
        @media (max-width:280px){
            font-size: .4rem;
            width: 120%;
        }
        :focus{
            outline: none;
        }
    }
`;
export const InnerContent = styled.div`
  margin-top: 40px;
  background: #ddd;
  border-radius: 10px;
  width: 100%;
  padding: 30px 15px;
  height: 180vh;
  overflow-y: auto;
  overflow-x: hidden;
`;