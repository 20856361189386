import { styled } from "styled-components";

export const Wrapper = styled.div`
    
    position: fixed;
    z-index: 1000;
    top:0;
    left: 0;
    width: 100%;
`;

export const Content = styled.div`
    padding: 4px;
    width: 10%;
    background-image: linear-gradient(to right, blue, #00057a);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: anim_line 5s ease infinite;
    
    @keyframes anim_line{
        0%{
            width: 0%;
        }
        20%{
            width: 20%;
        }
        30%{
            width: 30%;
        }
        50%{
            width: 50%;
        }
        70%{
            width: 70%;
        }
        90%{
            width: 90%;
        }
    }
`;