import React, { useState } from "react";
import CenteredPage from "components/app/centeredPage";
import Background from "components/Background";

import Loader from "components/app/loader";

import { HeadComponent } from "components/head";
import { reset_password_confirmation } from "actions/auth";

import voskool_logo from "images/voskool_logo.png";

const PasswordResetConfirmationPage = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState('');

    const onChangeEmail = (val='') => {
        setEmail(val);
    }
    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        reset_password_confirmation(email).then(res => {
            setLoading(false);
            setIsSent(true);
        }).catch(e => {
            setLoading(false);
            setIsSent(false);
            setError('Something Went Wrong');

        })
    }
    return(
        <>
            <HeadComponent title="Password Reset Page" />
            {loading && <Loader />}
            <Background bg_color="#26223a">
            <CenteredPage type="small" bg_style={{background:'#eed'}}>
                <div className="disp-block align-center">
                    <img style={{width:'150px', height:'50px'}} src={voskool_logo} alt="Voskool" />
                </div>
                {isSent ? (
                    <>
                        <h3>A password reset confirmation link will be sent to your email address anytime soon</h3>
                    </>
                ) : (
                    <>
                        <div className="align-center">
                            <h2>Wanna Reset Your Password!</h2>
                        </div>
                        <div className="disp-block align-center" style={{marginTop:'15px'}}>
                            <form onSubmit={e => onSubmit(e)}>
                                <div className="disp-block">
                                    <input type="email" onChange={e => onChangeEmail(e.target.value)} required placeholder="Enter Email Address" className="form-control font-small" value={email} />
                                </div>
                                {error && (
                                    <div>
                                        <h5 style={{color:'red'}}>{error}</h5>
                                    </div>
                                )}
                                <div className="disp-block" style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn font-small hover" style={{background:'#45f', borderRadius:'10px', color:'#eee'}}>Begin Password Reset</button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </CenteredPage>
            </Background>
        </>
    )
}

export default PasswordResetConfirmationPage;