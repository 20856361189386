import http, {http_file, http_, normal_http} from "api/http_setup";

export const appServices5 = {
    getAllSchoolSportHouse: (sid=0) => {
        return normal_http.get(`/voskool/app/school/sport/house/all_users/${sid}`);
    },
    initializeStudentExamSitNo: (sid=0) => {
        return normal_http.get(`/voskool/app/school/sesn/init/${sid}`);
    },
    getAllSchoolSubject: (school_id=0, csid='', q="", order_by="") => {
        return http.get(`/voskool/app/subject/school/all/${school_id}?csid=${csid}&q=${q}&order_by=${order_by}`);
    },
    getAllSessionM: (school_id=0, q="") => {
        return http.get(`/voskool/app/session/m/all/${school_id}?q=${q}`);
    },
    getCurrentSession: (school_id=0) => {
        return http.get(`/voskool/app/session/view_current/m/${school_id}`);
    },
    getAllClassSectionSchool: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/school/all/${school_id}`);
    },
    getAllClassSchoolItem: (school_id=0) => {
        return normal_http.get(`/voskool/app/class/school/all_item/${school_id}`);
    },
    getAllSchoolScores: (sid=0) => {
        return http.get(`/voskool/app/school/scores/all/${sid}`);
    },
    getAllTerm: (school_id=0) => {
        return http.get(`/voskool/app/term/all/${school_id}`);
    },
}

// for admin school app and general endpoints
export const appServices = {
    getNavData: (id) => {
       return http.get(`/voskool/school/nav/${id}`);
    },
    paymentRegisterSchool : (data={}) => {
        return http.post(`/voskool/payment/cart`, data);
    },
    insertRef : (data={}) => {
        return http.post(`/voskool/payment/cart/insert_ref`, data);
    },
    getRef: (uid=0) => {
        return http.get(`/voskool/payment/cart/get_ref/${uid}`);
    },
    insertClassSchool_: (sname="", data) => {
        return http_(sname).post(`/voskool/app/class/school/insert`, data);
    },
    getAllClassSchoolItem_: (sname="", school_id=0) => {
        return http_(sname).get(`/voskool/app/class/school/all_item/${school_id}`);
    },
    deleteClassSchool_: (sname="", id=0) => {
        return http_(sname).delete(`/voskool/app/class/school/delete/${id}`);
    },
    insertClassSectionSchool_: (sname="", data={}) => {
        return http_(sname).post(`/voskool/app/class_section/school/insert`, data);
    },
    getAllClassSchool_: (sname="", school_id=0, class_section_id=0) => {
        return http_(sname).get(`/voskool/app/class/school/all/${school_id}/${class_section_id}`);
    },
    updateClassSectionSchool_: (sname="", id=0, data={}) => {
        return http_(sname).put(`/voskool/app/class_section/school/update/${id}`, data);
    },
    deleteClassSectionSchool_: (sname="", id=0) => {
        return http_(sname).delete(`/voskool/app/class_section/school/delete/${id}`);
    },
    registerSchool : (data={}) => {
        return http_file.post(`/voskool/register`, data);
    },
    getRegisteredSchool : (id=0) => {
        return http.get(`/voskool/get_registered_school/${id}`);
    },
    updateRegisteredSchool : (sname="", id=0, data={}) => {
        return http_(sname).put(`/voskool/update_registered_school/${id}`, data);
    },
    insertSchoolBranch_: (sname="", data) => {
        return http_(sname).post(`/voskool/app/school_branch/insert`, data);
    },
    getAllSchoolBranch_: (sname='', school_id=0) => {
        return http_(sname).get(`/voskool/app/school_branch/all/${school_id}`);
    },
    updateSchoolBranch_: (sname="", id=0, data) => {
        return http_(sname).put(`/voskool/app/school_branch/update/${id}`, data);
    },
    deleteSchoolBranchMain_: (sname="", id=0) => {
        return http_(sname).delete(`/voskool/app/school_branch/delete_/${id}`);
    },
    createSchoolTrialDomain : (data={}) => {
        return http.post(`/voskool/trial/domain/create`, data);
    },
    getSchoolTrialDomain : () => {
        return http.get(`/voskool/trial/domain/get`);
    },
    getSchoolPaymentCart : (id=0) => {
        return http.get(`/voskool/payment/get_cart/${id}`);
    },
    getAllVoskoolPlan: () => {
        return normal_http.get(`/voskool/plan`);
    },
    encryptData: (id) => {
        return normal_http.get(`/voskool/encrypt/${id}`);
    },
    insertSchoolSession_: (sname="", data) => {
        return http_(sname).post(`/voskool/app/session_new/insert`, data);
    },
    updateSchoolSession_: (sname="", school_id=0, id=0, data) => {
        return http_(sname).put(`/voskool/app/session/update/${school_id}/${id}`, data);
    },
    insertSchoolBranch: data => {
        return http.post(`/voskool/app/school_branch/insert`, data);
    },
    getAllSchoolBranch: (school_id=0) => {
        return http.get(`/voskool/app/school_branch/all/${school_id}`);
    },
    
    updateSchoolBranch: (id=0, data) => {
        return http.put(`/voskool/app/school_branch/update/${id}`, data);
    },
    deleteSchoolBranch: (id=0) => {
        const data= {
            is_active:false
        }
        return http.put(`/voskool/app/school_branch/update/${id}`, data);
    },
    updateUserProfile: (id="", data) => {
        return http_file.put(`/voskool/app/user/update_profile/${id}`, data);
    },
    checkUserOnline: (id=0) => {
        const time = new Date().getTime() + 65000;
        const data = {is_online:time}
        return http.put(`/voskool/app/user/check_online/${id}`, data);
    },
    getOnlineUser: (id=0) => {
        return http.get(`/voskool/app/user/get_online/${id}`);
    },
    updateUserAct: (id=0) => {
        const data = {is_active:1}
        return http.put(`/voskool/app/user/check_online/${id}`, data);
    },
    insertClassSection: ( data) => {
        return http.post(`/voskool/app/class_section/insert`, data);
    },
    insertClassSectionSchool: ( data) => {
        return http.post(`/voskool/app/class_section/school/insert`, data);
    },
    updateClassSection: (id=0, data) => {
        return http.put(`/voskool/app/class_section/update/${id}`, data);
    },
    deleteClassSection: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/class_section/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/class_section/delete/${id}`, data);
        }
    },
    deleteClassSectionSchool: (id=0) => {
        return http.delete(`/voskool/app/class_section/school/delete/${id}`);
    },
    getAllClassSection: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/all/${school_id}`);
    },
    getAllClassSectionSchool: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/school/all/${school_id}`);
    },
    getClassSection: (id=0) => {
        return normal_http.get(`/voskool/app/class_section/view/${id}`);
    },
    getClassSectionSchool: (id=0) => {
        return http.get(`/voskool/app/class_section/school/view/${id}`);
    },
    getClassSectionSchool2: (school_id=0, csid=0) => {
        return http.get(`/voskool/app/class_section/school/get/${school_id}/${csid}`);
    },
    insertClass: ( data) => {
        return normal_http.post(`/voskool/app/class/insert`, data);
    },
    insertClassSchool: ( data) => {
        return http.post(`/voskool/app/class/school/insert`, data);
    },
    updateClass: (id=0, data) => {
        return http.put(`/voskool/app/class/update/${id}`, data);
    },
    updateClassSchool: (id=0, data) => {
        return http.put(`/voskool/app/class/school/update/${id}`, data);
    },
    deleteClass: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/class/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/class/delete/${id}`, data);
        }
    },
    deleteClassSchool: (id=0) => {
        return http.delete(`/voskool/app/class/school/delete/${id}`);
    },
    getAllClass: (school_id=0, class_section_id=0) => {
        return http.get(`/voskool/app/class/all/${school_id}/${class_section_id}`);
    },
    getAllClassSchool: (school_id=0, class_section_id=0) => {
        return http.get(`/voskool/app/class/school/all/${school_id}/${class_section_id}`);
    },
    getAllClassItem: (school_id=0) => {
        return http.get(`/voskool/app/class/all_item/${school_id}`);
    },
    getAllClassSchoolItem: (school_id=0) => {
        return http.get(`/voskool/app/class/school/all_item/${school_id}`);
    },
    getClass: (id=0) => {
        return normal_http.get(`/voskool/app/class/view/${id}`);
    },
    getClassSchool: (id=0) => {
        return http.get(`/voskool/app/class/school/view/${id}`);
    },
    getClassSchool2: (sid=0, cid=0) => {
        return http.get(`/voskool/app/class/school/view2/${sid}/${cid}`);
    },
    insertClassBlock: ( data) => {
        return http.post(`/voskool/app/class_block/insert`, data);
    },
    updateClassBlock: (id=0, data) => {
        return http.put(`/voskool/app/class_block/update/${id}`, data);
    },
    deleteClassBlock: (id=0) => {
        return http.delete(`/voskool/app/class_block/delete/${id}`); 
    },
    getAllClassBlock: (sid=0, cid="", csid="") => {
        return http.get(`/voskool/app/class_block/all/${sid}?csid=${csid}&cid=${cid}`);
    },
    insertSubjectgroup: ( data) => {
        return http.post(`/voskool/app/subject_group/insert`, data);
    },
    getAllSubjectGroup: (school_id=0) => {
        return http.get(`/voskool/app/subject_group/all/${school_id}`);
    },
    insertSubject: ( data) => {
        return http.post(`/voskool/app/subject/insert`, data);
    },
    updateSubject: (id=0, data) => {
        return http.put(`/voskool/app/subject/update/${id}`, data);
    },
    deleteSubject: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/subject/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/subject/delete/${id}`, data);
        }
    },
    getAllSubject: (school_id=0, csid='', q="") => {
        return http.get(`/voskool/app/subject/all/${school_id}?csid=${csid}&q=${q}`);
    },
    insertSubjectSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/insert`, data);
    },
    insertSubjectStaffAssignSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/staff_assign/insert`, data);
    },
    deleteSubjectStaffAssignSchool: (id="") => {
        return http.delete(`/voskool/app/subject/school/staff_assign/delete/${id}`);
    },
    insertSubjectStaffClassAssignSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/staff/class_assign/insert`, data);
    },
    deleteSubjectStaffClassAssignSchool: (id="") => {
        return http.delete(`/voskool/app/subject/school/staff/class_assign/delete/${id}`);
    },
    
    updateSubjectSchool: (id=0, data) => {
        return http.put(`/voskool/app/subject/school/update/${id}`, data);
    },
    deleteSubjectSchool: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/subject/school/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/subject/school/delete/${id}`, data);
        }
    },
    getAllSubjectSchool: (school_id=0, csid='', q="", order_by="") => {
        return http.get(`/voskool/app/subject/school/all/${school_id}?csid=${csid}&q=${q}&order_by=${order_by}`);
    },
    getAllSubjectGenre: () => {
        return http.get(`/voskool/app/subject_genre/all`);
    },
    
    
}