import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Wrapper, Content } from "./style";
import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import TelInput, { TelCountries } from "components/app/vo/SmallerComponents/telInput";
import { BACKEND_URL } from "actions/url";
import { COUNTRIES } from "api/voskool/services/countries";

import { validateSignUpForm2 } from "components/app/actions/validation";
// import { appServices } from "api/schoolApp/services";
import { sign_up } from "actions/auth";
// import { login } from "actions/auth";
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";


import Loader from "components/app/loader";
import { Link } from "react-router-dom";

const initialState = {
    results: [],
    count: 0,
    previous: '',
    next: '',
}
var loc;
var initialPhoneData;
if(localStorage.getItem('location')){
    loc = JSON.parse(localStorage.getItem('location'));
    initialPhoneData = {id:0, name:loc.location.country.name,
        code:loc.location.country.code.toLowerCase(),
        phone_code:`+${loc.location.country.calling_code}`,
        flag:loc.location.country.flag.wikimedia}
}
else{
    loc = {location:{country:{calling_code:''}}};
    initialPhoneData = {id:0, name:'',
        code:'',
        phone_code:'',
        flag:''}
}
const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const SignUpPage = ({state}) => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState("");
    const [phoneData, setPhoneData] = useState(initialPhoneData);
    const [formUser, setFormUser] = useState({phone_code:`+${loc.location.country.calling_code}`, phone:'', user_name:'', last_name:'', first_name:'', email:'', password:'', re_password:'', gender:''});
    const [formUserError, setFormUserError] = useState({});
    const [user, setUser] = useState(initialUserState)

    const [index, setIndex] = useState(1);

    const [search, setSearch] = useState('');

    const [countries, setCountries] = useState(initialState);

    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [submit, setSubmit] = useState('');
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [isSearchEmailParam, setIsSearchEmailParam] = useState(true);
    const [closeTelInput, setCloseTelInput] = useState(true);

    const [searchParams] = useSearchParams();
    

    const handleCountClick = (id, name, phone, code, flag) => {
        setPhoneData({...phoneData, id:id, name:name, phone_code:phone, code:code, flag:flag})
        setCloseTelInput(true);
    }
    const handleUserChange = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.value});
    }
    // const handleUserImageChange = (e, img_name='') => {
        
    //     if(img_name){
            
    //         setFormUser({...formUser, [e.target.name]:e.target.files[0], img:URL.createObjectURL(e.target.files[0]), [e.target.name + '_value']:e.target.value});
    //     }
    //     else{
    //         setFormUser({...formUser, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value})
    //     }
    // }
    
    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
    }
    const getAllCountries = (p, s, q) => {
        setLoading2(true);
        COUNTRIES(p, s, q).then(res => {
            setCountries(prev => ({
                ...res.data,
                results:
                    p > 1 ? [...prev.results, ...res.data.results] : [...res.data.results]
            }));
            setLoading2(false);
        }).catch(e => {
            setLoading2(false);
        })
    }
    const loadMore = (i) => {
        setIndex(i);
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
        setIndex(1);
        getAllCountries(1, 20, e.target.value)
    }
    // const addUserDetail = (timer=200) => {
    //     setTimeout(() => {
    //         if(formUser.profile_pic){
    //             const profileForm = new FormData()
    //             profileForm.append("profile_pic", formUser.profile_pic, formUser.profile_pic.name)
    //             appServices.updateUserProfile(formUser.user_name, profileForm).then(res => {
    //                 setFormUser({});
    //                 setSuccess({text:"Data Submitted Successfully", title:"Success"})
    //                 setLoading("");
    //                 setSubmit("");
    //             }).catch(e => {
    //                 setError({data:[e.response.data.name], title:"User Error"})
    //                 setLoading("");
    //                 console.log(e.response);
    //                 setSubmit("");
    //             })
    //         }
    //         else{
    //              setFormUser({});
    //             setSuccess({text:"Data Submitted Successfully", title:"Success"})
    //             setLoading("");
    //             setSubmit("");
            
    //         }
    //     }, timer)
        
    // }
    const onSubmit = (e) => {
        e.preventDefault();
        setSubmit('insert');
        setLoading('')
        setFormUserError(validateSignUpForm2(formUser));
        if(submit === "inserted"){
            const phone_comb = formUser.phone_code + formUser.phone;
            setLoading('loading');
            sign_up(userType, formUser.first_name, 
                formUser.last_name, formUser.email ? formUser.email : searchParams.get('e'), phone_comb.slice(1), formUser.password, formUser.re_password, formUser.gender).then(res => {
                    setFormUser({phone_code:`+${loc.location.country.calling_code}`, phone:'', user_name:'', last_name:'', first_name:'', email:'', password:'', re_password:'', gender:''});
                    // fetchUser()
                    setIsCreated(true);
                    setLoading("");
                    setSubmit("");
            }).catch(e => {
                setIsCreated(false);
                const error_data = e.response.data;
                const err = error_data[Object.keys(error_data)[0]];
                setError(err.length > 0 ? (Array.isArray(err) ? err[0] : err) : '');
                setLoading("");
                setSubmit("");  
            })
        }
    }
    const fetchUser =  async () => {
        setIsAuthenticated(false);
        setLoading3(true);
        await authHandler.load_user(load_user()).then(result => {
            setIsAuthenticated(result.isAuthenticated); 
            setLoading3(false)
            setUser({...result.data});
        }).catch(error => {
            setIsAuthenticated(false);
            setUser(initialUserState)
            setLoading3(false)
        })
    }
    
    useEffect(() => {
        if(isSearchEmailParam && searchParams.get('e')){
            setFormUser({...formUser, email:searchParams.get('e')});
            setIsSearchEmailParam(false);
        }
    }, [search, searchParams, formUser, isSearchEmailParam])
    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submit === 'insert'){
            setSubmit('inserted');
        }
    }, [formUserError, submit])
    useEffect(() => {
        if(user.user_type === "admin") return navigate(searchParams.get('red_url') ? searchParams.get('red_url') : '/')
        if(user.user_type === "school_owner") return navigate(searchParams.get('red_url') ? searchParams.get('red_url') : '/')
        // if(isAuthenticated && !user.user_type) return navigate('/')
        getAllCountries(index, 20, search);
    }, [index, search, searchParams, isAuthenticated, navigate, user])
    useEffect(() => {
        fetchUser()  
    }, [])

    const insertCountries = countries.results.map((item, i) => {
        return(
            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  key={i} className={item.code === phoneData.code ? `selected inner-content` : `inner-content`} onClick={() => handleCountClick(item.id, item.name, item.phone, item.code, `${BACKEND_URL}${item.flag}`)}>
                <div className="flag">
                    <img src={`${BACKEND_URL}${item.flag}`} alt="flag" />
                </div>
                <div>
                    <span className="text">{item.phone}</span>
                </div>
                <div>
                    <span className="text-small">{item.name.length > 5 ? `${item.name.slice(0, 8)}...` : item.name}</span>
                </div>
            </motion.div>
        )
    })

    return(
        <>
        {loading3 === false && (
        <>
        {!isAuthenticated && (
            <VoWrapper back={userType ? '/signup' : '/'} data={[]}>
            <Wrapper>
                <form onSubmit={e => onSubmit(e)}>
                    <Content>
                        {loading === "loading" && (<Loader />)}
                        {!userType && (
                            <>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group  mrn f-100">
                                    <label>Are you......</label>
                                    <select className="f-c w100" name="user_type" value={userType} onChange={e => handleUserTypeChange(e)}>
                                        <option value=""></option>
                                        <option value="school_owner">A School Owner/Principal</option>
                                    </select>
                                </motion.div>
                            </>
                        )}
                        
                        {userType && (
                            <>
                            {isCreated ? (
                                <>
                                    <h2>We have sent a confirmation link to your email address to verify your account</h2>
                                    <h5>Try logging in after getting our mail, by clicking <Link to="/">here</Link></h5>
                                </>
                            ) : (
                                <>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.1}} className="form-group f-33">
                                    <label>Enter Surname<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.last_name ? `f-c b-red`: `f-c`} name="last_name" placeholder="Surname" value={formUser.last_name} onChange={e => handleUserChange(e)} />
                                    {formUserError.last_name && (<span className="error">{formUserError.last_name}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.2}} className="form-group f-33">
                                    <label>Enter Firstname<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.first_name ? `f-c b-red`: `f-c`} name="first_name" value={formUser.first_name} onChange={e => handleUserChange(e)} placeholder="Firstname" />
                                    {formUserError.first_name && (<span className="error">{formUserError.first_name}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Enter Email Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="email" className={formUserError.email ? `f-c b-red`: `f-c`} name="email" placeholder="Email" value={formUser.email ? formUser.email : searchParams.get('e')} onChange={e => handleUserChange(e)} />
                                    {formUserError.email && (<span className="error">{formUserError.email}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Gender</label>
                                    <select className={formUserError.gender ? `f-c b-red`: `f-c`} name="gender" value={formUser.gender} onChange={e => handleUserChange(e)}>
                                        <option value="">Select Gender</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.6}} className="form-group f-33">
                                    <label>Enter Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <TelInput cname={formUserError.phone && `b-red`} onClick={() => {setCloseTelInput(!closeTelInput); setFormUser({...formUser, phone:phoneData.phone_code})}} close={closeTelInput}
                                        phone_code={phoneData.phone_code} flag={phoneData.flag} onChange={e => handleUserChange(e)} value={formUser.phone}>
                                        {!closeTelInput && (
                                            <TelCountries loading={loading2} next={countries.next} onClickLoadMore={() => loadMore(index+1)} onChange={(e) => handleSearch(e)} searched={search}>
                                                {insertCountries}
                                            </TelCountries>
                                        )}                 
                                    </TelInput>
                                    {formUserError.phone && (<span className="error">{formUserError.phone}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.7}} className="form-group f-33">
                                    <label>Enter Password<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="password" className={formUserError.password ? `f-c b-red`: `f-c`} name="password" placeholder="Password" value={formUser.password} onChange={e => handleUserChange(e)} />
                                    {formUserError.password && (<span className="error">{formUserError.password}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.8}} className="form-group f-33">
                                    <label>Confirm Password<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="password" className={formUserError.re_password ? `f-c b-red`: `f-c`} name="re_password" placeholder="Re-Enter Password" value={formUser.re_password} onChange={e => handleUserChange(e)} />
                                    {formUserError.re_password && (<span className="error">{formUserError.re_password}</span>)}
                                </motion.div>
                                {userType === "student" && (
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}} className="form-group align-center f-100">
                                        <span style={{color:'blue'}}>You can only enroll personally as a student if you don't have a guardian</span>
                                    </motion.div>
                                )}

                                {error && (
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}} className="form-group align-center f-100">
                                        <span style={{color:'red'}} className="font-slightly-small text-capitalize"><b>ERROR: </b>{error}</span>
                                    </motion.div>
                                )}
                                
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn hover-a br-5 shadow rem1 bg-black">Register <i className="fas fa-paper-plane"></i></button>
                                </motion.div>
                                </>
                            )}
                                
                            </>
                        )}
                        
                    </Content>
                </form>
            </Wrapper>
        </VoWrapper>
        )}
        </>           
        )}
        </>
    )
}

export default SignUpPage